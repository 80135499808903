import { Fragment } from "react";
import Globe from "react-globe.gl";
import { Transition } from "@headlessui/react";
import { useEffect, useState, useRef } from "react";
import { SizeMe } from "react-sizeme";
import { TextureLoader, SphereGeometry, MeshPhongMaterial, Mesh } from "three";

const pointsData = [
  {
    lat: 19.0558462,
    lng: -73.0513321,
    name: "Haiti",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Haiti.svg/2880px-Flag_of_Haiti.svg.png",
    description:
      "Deforestation began in Haiti when colonizers cut down trees for coffee, indigo, tobacco, and sugarcane plantations run by slave labor. Today, the leading cause of this deforestation is charcoal production and the consequences have been devastating. A mere 1% of its primary forests remain, and the UN estimates that 30% of those remaining trees are destroyed each year.",
    storyUrl: "https://www.edenprojects.org/our-work/haiti/",
  },
  {
    lat: -18.7792678,
    lng: 46.8344597,
    name: "Madagascar",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Madagascar.svg/2880px-Flag_of_Madagascar.svg.png",
    description:
      "Madagascar is one of the world’s top biodiversity conservation priorities because of its endemic species and severe habitat loss rates. Reforestation in Madagascar is important because the destruction of the mangrove estuaries along the coastline has caused mudflats to wash into the ocean, destroying once-productive fisheries and increasing the vulnerability of coastal communities to hurricanes, tsunamis, and floods. In the dry deciduous forests, deforestation threatens one of the world’s rarest and most diverse forest systems.",
    storyUrl: "https://www.edenprojects.org/our-work/madagascar/",
  },
  {
    lng: 35.5273354,
    lat: -18.6696553,
    name: "Mozambique",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Mozambique.svg/2880px-Flag_of_Mozambique.svg.png",
    description:
      "Mozambique is home to extensive biodiversity and varying landscapes with forests at the core of its social, environmental, and economic well-being. However, more than 8 million hectares of forest (over 30,000 square miles) have been destroyed. Cyclones, floods, cutting down trees for firewood and charcoal, clearing large areas for farmland, and commercial logging are the leading causes of deforestation in Mozambique.",
    storyUrl: "https://www.edenprojects.org/our-work/mozambique/",
  },
  {
    lng: 84.12576,
    lat: 28.3973623,
    name: "Nepal",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Flag_of_Nepal.svg/2880px-Flag_of_Nepal.svg.png",
    description:
      "Nepal is one of the least developed countries in the world with many communities depending on the natural environment for food, shelter, and income. Today, less than 30% of its original forests remain due to over-harvesting, forest fires, and agriculture. The effects of this degradation are devastating for the local communities and wildlife.",
    storyUrl: "https://www.edenprojects.org/our-work/nepal/",
  },
  {
    lng: 118.0148634,
    lat: -8.3405407,
    name: "Indonesia",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/2880px-Flag_of_Indonesia.svg.png",
    description:
      "Indonesia is one of the most biodiverse regions on the planet and home to about 23% of the world’s mangrove forests. However, in the last 50 years, Indonesia has lost over 40% of its mangrove forests. It is also consistently ranked in the top three countries for the highest rate of deforestation. The effects of deforestation have significantly impacted indigenous communities, who are often the first to feel the negative effects of climate change.",
    storyUrl: "https://www.edenprojects.org/our-work/indonesia/",
  },
  {
    lng: 37.9083264,
    lat: 0.1768696,
    name: "Kenya",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Kenya.svg/2880px-Flag_of_Kenya.svg.png",
    description:
      "Located on the eastern coast of Africa, Kenya is a country famous for its diverse wildlife and wide range of forest types that have long supported its communities. However, in recent decades these forests have experienced extreme environmental degradation. Human activities such as logging, charcoal burning, and illegal settling to create farmland are significant factors of deforestation. This has caused an increase in severe drought and extreme poverty. As a result, Kenya has committed to its reforestation, specifically looking to achieve a 10% forest cover, and we are working closely with the government to help accomplish these commitments.",
    storyUrl: "https://www.edenprojects.org/our-work/kenya/",
  },
  {
    lng: -86.2419,
    lat: 15.2,
    name: "Honduras",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Flag_of_Honduras.svg/2880px-Flag_of_Honduras.svg.png",
    description:
      "Between 2011 and 2015, Honduras lost 500,000 hectares (nearly 2,000 sq. miles, 5,000 sq. kilometers) of forest due to an infestation of disease-carrying pine beetles. The destruction of forests caused water tables to run low, protected areas to lose valuable biodiversity, and surrounding communities to lose jobs. By working directly with local communities to reforest these regions, our team is helping restore the water supply, create a natural habitat for local wildlife, and provide communities with job opportunities and resources to build up their economy.",
    storyUrl: "https://www.edenprojects.org/our-work/honduras/",
  },
  {
    lng: 34.8516127,
    lat: 9.0054018,
    name: "Ethiopia",
    flagUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Flag_of_Ethiopia.svg/2880px-Flag_of_Ethiopia.svg.png",
    description:
      "Inequitable development, slash-and-burn agricultural practices, charcoal production, and general overexploitation of forest resources have contributed significantly to the degradation of Ethiopia’s forest habitats. Plus, most communities in these rural regions rely directly on the land to meet their daily needs, such as food, water, and shelter. As forests continue to disappear, the less these communities are able to survive off the land’s natural resources.",
    storyUrl: "https://www.edenprojects.org/our-work/ethiopia/",
  },
];

const ImpactGlobe = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [displayedLocation, setDisplayedLocation] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const globeEl = useRef();

  useEffect(() => {
    let to;
    (function check() {
      if (globeEl.current) {
        // Add clouds sphere
        const CLOUDS_IMG_URL = process.env.PUBLIC_URL + "/clouds.png";
        const CLOUDS_ALT = 0.01;
        const CLOUDS_ROTATION_SPEED = 0.01; // deg/frame

        new TextureLoader().load(CLOUDS_IMG_URL, (cloudsTexture) => {
          const clouds = new Mesh(
            new SphereGeometry(
              globeEl.current.getGlobeRadius() * (1 + CLOUDS_ALT),
              75,
              75
            ),
            new MeshPhongMaterial({
              map: cloudsTexture,
              transparent: true,
            })
          );
          globeEl.current.scene().add(clouds);

          (function rotateClouds() {
            clouds.rotation.y += (CLOUDS_ROTATION_SPEED * Math.PI) / 180;
            requestAnimationFrame(rotateClouds);
          })();
        });

        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 0.25;
        globeEl.current.pointOfView({
          lat: 0,
          lng: 0,
          altitude: 2,
        });
        globeEl.current.controls().enableZoom = false;
        globeEl.current.controls().enablePan = false;
        globeEl.current.controls().enableRotate = true;
      } else {
        to = setTimeout(check, 1000);
      }
    })();
    return () => {
      if (to) {
        clearTimeout(to);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedLocation && globeEl.current) {
      globeEl.current.controls().autoRotate = false;
      globeEl.current.controls().autoRotateSpeed = 0;

      //Find location in pointsData
      const location = pointsData.find(
        (point) => point.name === selectedLocation
      );
      globeEl.current.pointOfView(
        {
          lat: location.lat - 20,
          lng: location.lng,
          altitude: 1.7,
        },
        1000
      );
      if (!popupOpen) {
        setTimeout(() => {
          setPopupOpen(true);
          setDisplayedLocation(location);
        }, 600);
      } else {
        setDisplayedLocation(location);
      }
    } else if (globeEl.current) {
      setPopupOpen(false);
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = 0.25;
      globeEl.current.pointOfView(
        {
          lat: 0,
          lng: 0,
          altitude: 2,
        },
        1000
      );
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!popupOpen) {
      setSelectedLocation(null);
      setTimeout(() => {
        setDisplayedLocation(null);
      }, 300);
    }
  }, [popupOpen]);

  return (
    <div className="hidden md:block">
      <section className="pt-32 bg-darkerBackgroundColor relative z-50">
        <h1 className="text-5xl font-headline font-semibold font-semibold text-center animate-fade">
          Where Eden Projects is working
        </h1>
        <p className="text-center mt-8 text-xl max-w-4xl mx-auto">
          At the center of Eden Reforestation Projects is the relationships with
          local communities. They work alongside them to produce, plant, and
          protect tens of millions of trees every month, thereby creating jobs
          to support them in restoring their local environment and economy
          long-term.
        </p>
        <div
          style={{
            position: "absolute",
            top: "22rem",
            left: 0,
            right: 0,
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <div className="flex flex-row justify-center relative">
            <button
              className={`${
                selectedLocation === "Haiti"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Haiti")}
            >
              Haiti
            </button>
            <button
              className={`${
                selectedLocation === "Madagascar"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Madagascar")}
            >
              Madagascar
            </button>
            <button
              className={`${
                selectedLocation === "Mozambique"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Mozambique")}
            >
              Mozaambique
            </button>
            <button
              className={`${
                selectedLocation === "Nepal"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Nepal")}
            >
              Nepal
            </button>
            <button
              className={`${
                selectedLocation === "Kenya"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Kenya")}
            >
              Kenya
            </button>
            <button
              className={`${
                selectedLocation === "Honduras"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Honduras")}
            >
              Honduras
            </button>
            <button
              className={`${
                selectedLocation === "Ethiopia"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Ethiopia")}
            >
              Ethiopia
            </button>
            <button
              className={`${
                selectedLocation === "Indonesia"
                  ? "bg-primaryGreen text-white"
                  : "bg-black text-white"
              } mr-4 px-4 py-2 rounded-md transition-all duration-300 ease-in-out`}
              onClick={() => setSelectedLocation("Indonesia")}
            >
              Indonesia
            </button>
            {/* Reset button */}
            {selectedLocation && (
              <button
                className={`${
                  selectedLocation === ""
                    ? "bg-primaryGreen text-white"
                    : "bg-gray-50 text-black"
                } mr-4 px-4 py-2 rounded-md transition-all border border-black duration-300 ease-in-out animate-enter`}
                onClick={() => setSelectedLocation(null)}
              >
                Reset
              </button>
            )}
          </div>
        </div>
      </section>
      <div
        className="overflow-hidden bg-darkerBackgroundColor items-center relative justify-center z-0 w-full flex-col mx-auto border-black border-b-2"
        style={{ height: 800 }}
      >
        <Transition
          appear
          show={popupOpen}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-100 scale-0 translate-x-96"
          enterTo="opacity-100 scale-100 translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95 translate-y-8"
        >
          <div
            className=" bg-white/80 w-96 backdrop-blur-xl rounded-md border border-black/90"
            style={{
              position: "absolute",
              zIndex: 100,
              top: "15rem",
              left: "12vw",
            }}
          >
            <div className="p-8">
              <div className="flex items-center">
                <img
                  src={displayedLocation ? displayedLocation.flagUrl : ""}
                  className="h-8 mr-3 border border-black/10"
                  alt={displayedLocation ? displayedLocation.name : ""}
                />
                <div className="font-headline font-semibold text-2xl">
                  {displayedLocation ? displayedLocation.name : ""}
                </div>
                <button className="ml-auto" onClick={() => setPopupOpen(false)}>
                  <svg
                    className="w-4 h-4 text-black"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <p className="text-black text-base mt-4 mb-4">
                {displayedLocation ? displayedLocation.description : ""}
              </p>
              <a
                href={displayedLocation ? displayedLocation.storyUrl : ""}
                rel="noreferrer"
                target={"_blank"}
                className="font-bold mt-4 mb-4 text-black text-md underline"
              >
                Learn more
              </a>
            </div>
          </div>
        </Transition>

        <SizeMe>
          {({ size: { width } }) => (
            <Globe
              ref={globeEl}
              width={width}
              height={1800}
              animateIn={false}
              globeImageUrl="/earth.jpg"
              showAtmosphere={true}
              backgroundColor="rgb(237 237 226)"
              atmosphereColor="deepskyblue"
              atmosphereAltitude={0.19}
              labelsData={pointsData}
              labelLat={(d) => d.lat}
              labelLng={(d) => d.lng}
              labelText={(d) => d.name}
              labelSize={1.25}
              labelColor={() => "rgb(255, 255, 255)"}
              labelResolution={4}
              labelsTransitionDuration={0}
              htmlElementsData={pointsData}
              htmlElement={(d) => {
                const el = document.createElement("div");
                el.style.width = "20px";
                el.style.height = "20px";
                el.style.borderRadius = "50%";
                el.style.backgroundColor = "rgba(2, 165, 126, 0.7)";
                el.style["pointer-events"] = "auto";
                el.style.cursor = "pointer";
                el.style["box-shadow"] = "0 0 0 1px rgb(255, 255, 255)";

                // Width and height transition
                el.style.transition = "width 0.3s, height 0.3s";
                // On hover make it bigger
                el.onmouseenter = () => {
                  el.style.width = "30px";
                  el.style.height = "30px";
                };
                el.onmouseleave = () => {
                  el.style.width = "20px";
                  el.style.height = "20px";
                };
                // On click, set the selected location

                el.onclick = () => setSelectedLocation(d.name);
                return el;
              }}
            />
          )}
        </SizeMe>
      </div>
    </div>
  );
};

export default ImpactGlobe;
