import { Dialog, Transition } from "@headlessui/react";
import { UploadIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the FilePond plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function UploadPicModal({
  isOpen,
  closeModal,
  getCurrentTenant,
}) {
  const [file, setFile] = useState([]);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <UploadIcon
                    className="-ml-0.5 mt-0.5 mr-2 h-5 w-5 float-left"
                    aria-hidden="true"
                  />
                  Upload new company picture
                </Dialog.Title>
                <div className="mt-3">
                  <p className="text-sm text-gray-500">
                    The picture should be 1:1 ratio and max 1000px * 1000px
                  </p>
                  <div className="mt-4 mb-4">
                    <FilePond
                      files={file}
                      allowMultiple={false}
                      onupdatefiles={setFile}
                      allowImageResize={true}
                      imageResizeTargetWidth={400}
                      imageResizeTargetHeight={400}
                      name="picture"
                      server={{
                        process: {
                          url: "/api/upload-image",
                          onload: async () => {
                            await new Promise((r) => setTimeout(r, 2000));
                            await getCurrentTenant();
                            closeModal(true);
                          },
                        },
                      }}
                      labelIdle='Drag & Drop your image here or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => closeModal(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
