/*                                */
/*     State store               */
/*                              */

import { proxy, useSnapshot, subscribe } from "valtio";
import * as api from "../services/api";

const state = proxy({
  currentUser: null,
  currentTenant: null,
  currentTenantUsers: null,
  viewableUser: null,
  viewableTag: null,
  viewableTagRecognitionsMonthly: null,
  viewableTagPreviousPeriod: null,
  reportingData: null,
  reportingDataMonth: null,
  reportingData60d: null,
  reportingRecognitionsMonthly: null,
  isLoading: false,
});

const getCurrentUser = async () => {
  state.isLoading = true;
  let data = await api.getCurrentUser();
  if (data.user) {
    state.currentUser = data.user;
  } else {
    state.currentUser = null;
  }
  state.isLoading = false;
};

const getCurrentUserRecognitions = async () => {
  state.isLoading = true;
  let data = await api.getCurrentUserRecognitions();
  if (data.user) {
    state.currentUser = data.user;
  } else {
    state.currentUser = null;
  }
  state.isLoading = false;
};

const getCurrentTenant = async () => {
  state.isLoading = true;
  let data = await api.getCurrentTenant();
  if (data.tenant) {
    state.currentTenant = data.tenant;
    // Get tagCollections also
    let tcData = await api.getTenantTagCollections();
    state.currentTenant.tagCollections = tcData.tag_collections;
    state.isLoading = false;
  } else {
    state.currentTenant = null;
    state.isLoading = false;
  }
};

const getCurrentTenantUsers = async () => {
  state.isLoading = true;
  let data = await api.getCurrentTenantUsers();
  if (data.users) {
    state.currentTenantUsers = data.users;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getViewableUser = async (userId) => {
  state.isLoading = true;
  let data = await api.getViewableUser(userId);
  if (data.viewableUser) {
    state.viewableUser = data.viewableUser;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getTenantDataReporting = async (start_date, end_date, monthly) => {
  state.isLoading = true;
  let data = await api.getTenantDataForReporting(start_date, end_date);
  if (data) {
    if (monthly && monthly === "30d") {
      state.reportingDataMonth = data;
    } else if (monthly && monthly === "60d") {
      state.reportingData60d = data;
    } else {
      state.reportingData = data;
    }
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getTenantRecognitionsMonthly = async (month, year) => {
  state.isLoading = true;
  let data = await api.getTenantRecognitionsMonthly(month, year);
  if (data) {
    state.reportingRecognitionsMonthly = data;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getViewableTag = async (tagId, startDate, endDate) => {
  state.isLoading = true;
  let data = await api.getViewableTag(tagId, startDate, endDate);
  if (data.viewableTag) {
    state.viewableTag = data.viewableTag;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const clearViewableTag = () => {
  state.viewableTag = null;
};

const getViewableTagPreviousPeriod = async (tagId, startDate, endDate) => {
  state.isLoading = true;
  let data = await api.getViewableTag(tagId, startDate, endDate);
  if (data.viewableTag) {
    state.viewableTagPreviousPeriod = data.viewableTag;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getTagRecognitionsByMonth = async (month, year, tagId) => {
  state.isLoading = true;
  let data = null;
  data = await api.getTagRecognitionsMonthly(month, year, tagId);
  if (data) {
    state.viewableTagRecognitionsMonthly = data;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

export {
  state,
  useSnapshot,
  subscribe,
  getCurrentUser,
  getCurrentUserRecognitions,
  getViewableUser,
  getCurrentTenant,
  getCurrentTenantUsers,
  getTenantDataReporting,
  getTenantRecognitionsMonthly,
  clearViewableTag,
  getViewableTag,
  getViewableTagPreviousPeriod,
  getTagRecognitionsByMonth,
};
