import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { getCurrentUser, getCurrentTenant } from "./state/stateStore";
import ProtectedRoute from "./auth/ProtectedRoute";

//Pages
import Login from "./components/Login";
import Dashboard from "components/Dashboard/Dashboard";
import Recognitions from "components/Recognitions/Recognitions";
import ImpactDashboard from "./components/Impact/ImpactDashboard";
import Reporting from "components/Reporting/Reporting";
import Account from "components/Account/Account";
import Tags from "components/Tags/Tags";
import ManageTags from "components/Tags/Manage";
import ManageUsers from "components/Account/ManageUsers";
import NotInstalled from "components/NotInstalled";
import Profile from "components/Profile/Profile";
import Landing from "components/Landing/Landing";
import Installed from "components/Installed";
import Tag from "components/Tags/TagPage/Tag";
import Settings from "components/Settings/Settings";
import PersonalShare from "components/Impact/PersonalShare";

const App = () => {
  useEffect(() => {
    const fetchData = async () => {
      await getCurrentUser();
      await getCurrentTenant();
    };
    fetchData();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/landing" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/notinst" element={<NotInstalled />} />
        <Route path="/installed" element={<Installed />} />
        <Route path="/impact/:domain" element={<ImpactDashboard />} />
        <Route path="/share/:user" element={<PersonalShare />} />
        <Route
          index
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/recognitions"
          element={
            <ProtectedRoute>
              <Recognitions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reporting"
          element={
            <ProtectedRoute>
              <Reporting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tags"
          element={
            <ProtectedRoute>
              <Tags />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tags/manage"
          element={
            <ProtectedRoute checkAdmin={true}>
              <ManageTags />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tag/:tagId"
          element={
            <ProtectedRoute>
              <Tag />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute checkAdmin={true}>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account/users/manage"
          element={
            <ProtectedRoute checkAdmin={true}>
              <ManageUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:userId"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
