import React, { useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MinusSmIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { getProfilePictureURI } from "../Helpers/helpers";

const Table = ({ columns, data, showAll, isCompact, showNote }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ["picture_uri", "_id"],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <ReactTooltip effect="solid" />
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-md">
              <table
                {...getTableProps()}
                className="min-w-full divide-y table-fixed divide-gray-300"
              >
                <thead className="bg-slate-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-medium text-slate-800"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}
                            {!column.isSorted && (
                              <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                <MinusSmIcon
                                  className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const picture_uri = row.values.picture_uri;
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell) => {
                          let classes =
                            "pt-6 pb-4 pr-3 text-sm sm:pl-6 align-top";
                          switch (cell.column.id) {
                            case "note":
                              classes =
                                "pt-6 pb-4 pl-4 pr-3 text-sm sm:pl-6 w-2/5 align-top";
                              break;
                            case "date":
                              classes =
                                "pt-6 pb-4 pl-4 pr-3 text-sm sm:pl-6 w-40 align-top";
                              break;
                            case "sender_name":
                              classes =
                                "py-4 pl-4 pr-3 text-sm sm:pl-6 w-60 align-top";
                              break;
                            case "receivers":
                              classes =
                                "py-4 pl-4 pr-3 text-sm sm:pl-6 w-60 align-top";
                              break;
                            case "tags":
                              classes =
                                "py-4 pl-4 pr-3 text-sm sm:pl-6 w-72 align-top";
                              break;
                            default:
                              break;
                          }
                          return (
                            <td {...cell.getCellProps()} className={classes}>
                              <div className="text-gray-700 flex items-center">
                                {cell.column.id === "sender_name" ? (
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={getProfilePictureURI(picture_uri)}
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                <span
                                  className={
                                    cell.column.id === "sender_name"
                                      ? "ml-4"
                                      : ""
                                  }
                                >
                                  {cell.render("Cell", {
                                    showAll: showAll,
                                    showNote: showNote,
                                  })}
                                </span>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {!isCompact && (
              <div className="py-3 flex items-center justify-between rounded-md mt-4">
                <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      <span>
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                      </span>
                    </p>
                  </div>
                  <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <select
                      className="relative py-2 pl-3 pr-10 text-left bg-white rounded-md border-none shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize} per page
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <nav
                      className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                      aria-label="Pagination"
                    >
                      <button
                        className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>

                      <button
                        className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const RecognitionsTable = ({ recognitions, isCompact, mode }) => {
  const [showAll, setShowAll] = useState(null);
  const [showNote, setShowNote] = useState(null);
  const [showNames, setShowNames] = useState(null);
  let navigate = useNavigate();

  let viewMode = mode ? mode : "RECEIVED";

  const columns = [
    {
      Header: "Id",
      accessor: "_id",
    },
    {
      Header: "Picture",
      accessor: "picture_uri",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: (props) => {
        return <div> {moment(props.value).format("MMMM Do YYYY")}</div>;
      },
    },
    {
      Header: "Name",
      accessor: viewMode === "RECEIVED" ? "sender_name" : "receivers",

      Cell: (props) => {
        if (viewMode === "RECEIVED") {
          return props.value;
        } else if (viewMode === "SENT") {
          let receivers = props.value;
          const index = props.cell.row.index;
          if (receivers.length === 1) {
            return (
              <>
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-8 rounded-full float-left my-2"
                    src={getProfilePictureURI(receivers[0].picture_uri)}
                    alt={receivers[0].name}
                  />
                  <span className="ml-2 mt-4 float-left">
                    {receivers[0].name}
                  </span>
                </div>
              </>
            );
          } else if (showNames !== index && receivers.length <= 6) {
            return (
              <>
                <div className="flex -space-x-2 overflow-hidden">
                  {props.value.map((receiver) => {
                    return (
                      <div data-tip={receiver.name} className="cursor-pointer">
                        <img
                          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                          src={getProfilePictureURI(receiver.picture_uri)}
                          alt={receiver.name}
                        />
                      </div>
                    );
                  })}
                </div>
                <h5
                  className="text-xs block text-green-600 mt-2 cursor-pointer"
                  onClick={() => setShowNames(index)}
                >
                  Show names
                </h5>
              </>
            );
          } else if (showNames !== index && receivers.length > 6) {
            return (
              <>
                <div className="flex -space-x-2 overflow-hidden">
                  {receivers.slice(0, 2).map((receiver) => {
                    return (
                      <div data-tip={receiver.name} className="cursor-pointer">
                        <img
                          className="inline-block h- w-8 rounded-full ring-2 ring-white"
                          src={getProfilePictureURI(receiver.picture_uri)}
                          alt={receiver.name}
                        />
                      </div>
                    );
                  })}
                  <a class="flex items-center justify-center w-8 h-8 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800">
                    +{receivers.length - 6}
                  </a>
                </div>
                <h5
                  className="text-xs block text-green-600 mt-2 cursor-pointer"
                  onClick={() => setShowNames(index)}
                >
                  Show names
                </h5>
              </>
            );
          } else {
            return (
              <>
                <div className="block">
                  {props.value.map((receiver) => {
                    return (
                      <div className="border-b py-2">
                        <img
                          className="inline-block h-6 w-6 rounded-full"
                          src={getProfilePictureURI(receiver.picture_uri)}
                          alt={receiver.name}
                        />
                        <span className="ml-2  inline-block pt-2">
                          {receiver.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="text-xs text-green-600 mt-4 cursor-pointer"
                  onClick={() => setShowNames(null)}
                >
                  Hide names
                </div>
              </>
            );
          }
        }
      },
    },
    {
      Header: "Note",
      accessor: "note",
      Cell: (props) => {
        let note = props.value;
        const index = props.cell.row.index;
        const showNote = props.showNote;
        let displayNote;
        if (showNote === index) {
          displayNote = note;
        } else {
          displayNote = note.slice(0, 140);
        }
        return (
          <>
            {displayNote}
            {showNote !== index && note.length > 140 && (
              <div
                className="text-xs text-green-600 mt-2 cursor-pointer"
                onClick={() => setShowNote(index)}
              >
                Show all
              </div>
            )}
            {showNote === index && note.length > 140 && (
              <div
                className="text-xs text-green-600 mt-2 cursor-pointer"
                onClick={() => setShowNote(null)}
              >
                Show less
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "Seeds",
      accessor: "amount_seeds",
      Cell: (props) => {
        return <div> {props.value} x 🌱</div>;
      },
    },
    {
      Header: "Tags",
      accessor: "tags",
      Cell: (props) => {
        const tags = props.value;
        const index = props.cell.row.index;
        const showAll = props.showAll;
        return (
          <div>
            {showAll === index && (
              <>
                {tags.map((tag) => {
                  return (
                    <span
                      className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 cursor-pointer rounded-md text-sm font-medium bg-blue-100 text-blue-800"
                      onClick={() => {
                        if (tag._id) {
                          navigate(`/tag/${tag._id}`);
                        }
                      }}
                    >
                      {tag.emoji} {tag.name}
                    </span>
                  );
                })}
                {tags.length > 0 && (
                  <div
                    className="text-xs text-green-600 mt-2 ml-2 cursor-pointer"
                    onClick={() => setShowAll(null)}
                  >
                    Show less
                  </div>
                )}
              </>
            )}
            {showAll !== index && (
              <>
                {tags.slice(0, 3).map((tag) => {
                  return (
                    <span
                      className="inline-flex items-center cursor-pointer px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-sm font-medium bg-blue-100 text-blue-800"
                      onClick={() => {
                        if (tag._id) {
                          navigate(`/tag/${tag._id}`);
                        }
                      }}
                    >
                      {tag.emoji} {tag.name}
                    </span>
                  );
                })}
                {tags.length > 4 && (
                  <div
                    className="text-xs text-green-600 mt-2 ml-2 cursor-pointer"
                    onClick={() => setShowAll(index)}
                  >
                    Show all
                  </div>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <div className="overflow-x-auto lg:-mx-8">
          <div className="w-full py-2 align-middle md:px-6 lg:px-8">
            <Table
              columns={columns}
              data={recognitions}
              navigate={navigate}
              showAll={showAll}
              isCompact={isCompact}
              showNote={showNote}
              showNames={showNames}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecognitionsTable;
