import React, { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { state, useSnapshot, getCurrentTenant } from "../state/stateStore";

const consentLink =
  "https://login.microsoftonline.com/common/adminconsent?client_id=" +
  process.env.REACT_APP_BOT_ID;

const Installed = () => {
  useEffect(() => {
    getCurrentTenant();
  }, []);

  const [steps, setSteps] = useState([
    { id: 1, name: "1. Subscription activated", status: "complete" },
    { id: 2, name: "2. Grant permissions", status: "current" },
    { id: 3, name: "3. Add to Teams", status: "upcoming" },
  ]);

  const openConsentLink = () => {
    setSteps([
      { id: 1, name: "1. Subscription activated", status: "complete" },
      { id: 2, name: "2. Grant permissions", status: "complete" },
      { id: 3, name: "3. Add to Teams", status: "current" },
    ]);
    window.open(consentLink, "_blank");
  };

  const snap = useSnapshot(state);
  const currentTenant = snap.currentTenant;
  const isLoading = snap.isLoading;

  if (!isLoading && currentTenant) {
    return (
      <div className="h-screen flex bg-coolBackground1">
        <div className="w-full max-w-3xl m-auto bg-white rounded-3xl shadow-sm py-10 px-16 xs:max-w-sm">
          <img
            src="/favicon.png"
            alt="Welcome to Evergreen"
            className="mx-auto rounded-3xl h-32 w-32 text-center mb-6"
          />
          <h1 className="text-2xl font-medium text-primary mt-4 text-center">
            You've subscribed to Evergreen 🌳
          </h1>
          <p className="font-light text-mutedTextColor mt-4 text-center">
            You have succesfully started your Evergreen subscription. Next you
            need to complete these of steps to start using Evergreen.
          </p>
          <hr className="my-6" />
          <div className="flex justify-center">
            <nav className="flex mx-6" aria-label="Progress">
              <ol className="space-y-6">
                {steps.map((step) => (
                  <li key={step.name}>
                    {step.status === "complete" ? (
                      <a href={step.href} className="group">
                        <span className="flex items-start">
                          <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                            <CheckCircleIcon
                              className="h-full w-full text-green-600 group-hover:text-green-800"
                              aria-hidden="true"
                            />
                          </span>
                          <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            {step.name}
                          </span>
                        </span>
                      </a>
                    ) : step.status === "current" ? (
                      <a
                        href={step.href}
                        className="flex items-start"
                        aria-current="step"
                      >
                        <span
                          className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                          aria-hidden="true"
                        >
                          <span className="absolute h-4 w-4 rounded-full bg-green-200" />
                          <span className="relative block w-2 h-2 bg-green-600 rounded-full" />
                        </span>
                        <span className="ml-3 text-sm font-medium text-green-600">
                          {step.name}
                        </span>
                      </a>
                    ) : (
                      <a href={step.href} className="group">
                        <div className="flex items-start">
                          <div
                            className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                            aria-hidden="true"
                          >
                            <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                          </div>
                          <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            {step.name}
                          </p>
                        </div>
                      </a>
                    )}
                  </li>
                ))}
              </ol>
            </nav>

            {steps[1].status === "current" && (
              <div className="mx-6">
                <p className="font-light text-sm text-mutedTextColor">
                  Grant permissions to Evergreen via the consent link button.
                </p>
                <a
                  className="mx-auto inline-flex cursor-pointer mt-4 items-center px-4 py-2 border border-transparent text-md leading-4 font-medium text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => openConsentLink()}
                  target="_blank"
                >
                  Grant permissions
                </a>
              </div>
            )}
            {steps[2].status === "current" && (
              <div className="mx-6">
                <p className="font-light text-sm text-mutedTextColor">
                  Add Evergreen app to your Teams, if you haven't done it
                  already.
                </p>
                <a
                  className="mx-auto inline-flex mt-4 items-center px-4 py-2 border border-transparent text-md leading-4 font-medium text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  href="https://appsource.microsoft.com/en-us/product/web-apps/WA200004548?tab=Overview"
                >
                  Add Evergreen to Teams
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <svg
        role="status"
        className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    );
  }
};

export default Installed;
