import { useEffect, useState } from "react";
import { state, useSnapshot, getCurrentTenant } from "../../state/stateStore";
import {
  TagIcon,
  CollectionIcon,
  ChevronLeftIcon,
  PencilAltIcon,
  TrashIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import TagModal from "./TagModal";
import CollectionModal from "./CollectionModal";
import * as api from "../../services/api";

const ManageTags = () => {
  let [isTagAddOpen, setIsTagAddOpen] = useState(false);
  let [isCollectionAddOpen, setIsCollectionAddOpen] = useState(false);
  let [activeTagCollection, setActiveTagCollection] = useState(null);
  let [activeTag, setActiveTag] = useState(null);
  let [deleteConfirm, setDeleteConfirm] = useState(null);
  let [deleteCollectionConfirm, setDeleteCollectionConfirm] = useState(null);

  const closeAddModal = () => {
    setActiveTag(null);
    setActiveTagCollection(null);
    setIsTagAddOpen(false);
  };

  const closeAddCollectionModal = () => {
    setActiveTagCollection(null);
    setIsCollectionAddOpen(false);
  };

  const openAddModal = (tag, tagCollection) => {
    if (tag) {
      setActiveTag(tag);
    } else {
      setActiveTag(null);
    }
    setActiveTagCollection(tagCollection);
    setIsTagAddOpen(true);
  };

  const openAddCollectionModal = (tagCollection) => {
    setActiveTagCollection(tagCollection);
    setIsCollectionAddOpen(true);
  };

  useEffect(() => {
    getCurrentTenant();
  }, []);

  const snap = useSnapshot(state);
  const currentTenant = snap.currentTenant;
  const isLoading = snap.isLoading;
  const navigate = useNavigate();

  if (!isLoading && currentTenant) {
    const tagCollections = currentTenant.tagCollections;
    return (
      <>
        <TagModal
          isOpen={isTagAddOpen}
          closeModal={closeAddModal}
          tagCollection={activeTagCollection}
          tag={activeTag}
        />
        <CollectionModal
          isOpen={isCollectionAddOpen}
          closeModal={closeAddCollectionModal}
          tagCollection={activeTagCollection}
        />
        <section className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container my-8">
              <div className="flex w-full">
                <h2 className="text-2xl flex float-left inline-flex font-semibold text-slate-600 leading-relaxed">
                  <TagIcon
                    className="float-left h-8 w-8 mt-1 mr-2"
                    aria-hidden="true"
                  />{" "}
                  Manage Tags
                </h2>
                <button
                  onClick={() => navigate(-1)}
                  className="float-left ml-6 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-slate-700 bg-slate-100 hover:bg-slate-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                >
                  <ChevronLeftIcon
                    className="float-left h-4 w-4 mr-1 -ml-1"
                    aria-hidden="true"
                  />
                  Back
                </button>

                <button
                  onClick={() => openAddCollectionModal(null)}
                  className="float-left ml-4 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md bg-primaryGreen text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <CollectionIcon
                    className="float-left h-4 w-4 mr-1 -ml-1"
                    aria-hidden="true"
                  />
                  Create new tag collection
                </button>
              </div>
            </div>
            <section className="w-full">
              <div className="flex flex-wrap -mx-2">
                {tagCollections && tagCollections.length === 0 && (
                  <div className="h-full px-6 py-24 bg-slate-50 shadow text-center rounded-md w-full">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No tag collections or tags
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a new tag collection
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        onClick={() => openAddCollectionModal(null)}
                      >
                        Create new tag collection
                      </button>
                    </div>
                  </div>
                )}
                {tagCollections &&
                  tagCollections.map((tagCollection, i) => {
                    return (
                      <div className="w-full lg:w-1/2 px-2 mt-2 mb-2 mb-4">
                        <div className="h-full py-2 px-2 bg-slate-50 shadow rounded-md">
                          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                              {deleteCollectionConfirm !==
                                tagCollection._id && (
                                <>
                                  <div className="ml-4 mt-4">
                                    <div className="flex items-center text-lg">
                                      <span className="text-slate-700">
                                        <CollectionIcon
                                          className="float-left h-5 w-5 mt-1 mr-2"
                                          aria-hidden="true"
                                        />{" "}
                                        {tagCollection.collection_name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="ml-4 mt-4 flex-shrink-0 flex">
                                    <button
                                      type="button"
                                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                      onClick={() =>
                                        openAddCollectionModal(tagCollection)
                                      }
                                    >
                                      <span>Edit</span>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDeleteCollectionConfirm(
                                          tagCollection._id
                                        )
                                      }
                                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                      <span>Delete</span>
                                    </button>
                                  </div>
                                </>
                              )}
                              {deleteCollectionConfirm ===
                                tagCollection._id && (
                                <div className="ml-4 mt-4 flex-shrink-0 flex">
                                  <>
                                    <span className="text-sm text-slate-800 mt-2 mr-6">
                                      Are you sure you want to delete this tag
                                      collection?
                                    </span>
                                    <div
                                      className="inline-flex px-4 py-2 cursor-pointer text-primaryGreen text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                      onClick={async () => {
                                        await api.deleteTenantTagCollection(
                                          tagCollection._id
                                        );
                                        setActiveTagCollection(null);
                                        setDeleteCollectionConfirm(null);
                                        getCurrentTenant();
                                      }}
                                    >
                                      I'm sure
                                    </div>
                                    <div
                                      className="inline-flex px-4 py-2 cursor-pointer text-red-500 text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                      onClick={() =>
                                        setDeleteCollectionConfirm(null)
                                      }
                                    >
                                      Cancel
                                    </div>
                                  </>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="px-4 pt-4 w-full pb-4">
                            <ul className="w-full">
                              {tagCollection.tags.map((tag, i) => {
                                return (
                                  <li key={i}>
                                    <a className="block bg-white mt-2 shadow rounded-md w-full hover:bg-gray-50">
                                      <div className="px-4 py-4 flex items-center sm:px-6">
                                        {deleteConfirm !== tag.id && (
                                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                            <div className="truncate">
                                              <div className="flex text-sm">
                                                <p className="font-medium text-slate-800 truncate">
                                                  {tag.emoji} {tag.name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <div className="ml-5 flex-shrink-0 flex items-center justify-center">
                                          {deleteConfirm === tag._id && (
                                            <>
                                              <span className="text-sm text-slate-800 mr-8">
                                                Are you sure you want to delete
                                                this tag?
                                              </span>
                                              <div
                                                className="inline-flex px-4 py-2 cursor-pointer text-primaryGreen text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                onClick={async () => {
                                                  await api.deleteTenantTag(
                                                    tag._id
                                                  );
                                                  setActiveTag(null);
                                                  setActiveTagCollection(null);
                                                  setDeleteConfirm(null);
                                                  getCurrentTenant();
                                                }}
                                              >
                                                I'm sure
                                              </div>
                                              <div
                                                className="inline-flex px-4 py-2 cursor-pointer text-red-500 text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                onClick={() =>
                                                  setDeleteConfirm(null)
                                                }
                                              >
                                                Cancel
                                              </div>
                                            </>
                                          )}
                                          {deleteConfirm !== tag._id && (
                                            <>
                                              <div
                                                className="inline-flex px-4 py-2 text-slate-500 hover:text-yellow-500 cursor-pointer text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                onClick={() => {
                                                  openAddModal(
                                                    tag,
                                                    tagCollection
                                                  );
                                                }}
                                              >
                                                <PencilAltIcon
                                                  className="float-left h-4 w-4"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                              <div
                                                className="inline-flex px-4 py-2 text-slate-500 hover:text-red-500 cursor-pointer text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                onClick={() => {
                                                  setDeleteConfirm(tag._id);
                                                }}
                                              >
                                                <TrashIcon
                                                  className="float-left h-4 w-4"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                            <div className="block w-full flex justify-center mt-4">
                              <button
                                type="button"
                                className="flex items-center px-3 py-2 border border-transparent float-right text-xs leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none"
                                onClick={() =>
                                  openAddModal(null, tagCollection)
                                }
                              >
                                <PlusCircleIcon
                                  className="-ml-1 mr-1 h-3 w-3"
                                  aria-hidden="true"
                                />
                                Add tag to {tagCollection.collection_name}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>
          </div>
        </section>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default ManageTags;
