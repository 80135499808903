import ReactTooltip from "react-tooltip";
import Chart from "react-apexcharts";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";

function text_truncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

const TagsStatsBoxes = ({ tagsData }) => {
  let chartData = [];
  let labelsArray = [];

  let collectionChartData = [];
  let collectionLabelsArray = [];

  tagsData.rankingArray.forEach((tag, i) => {
    if (i < 5) {
      chartData.push(tag.count);
      labelsArray.push(text_truncate(tag.name, 10));
    } else {
      chartData[4] += tag.count;
      labelsArray[4] = "Other";
    }
  });

  tagsData.collectionRankingArray.forEach((collection, i) => {
    if (i < 5) {
      collectionChartData.push(collection.count);
      collectionLabelsArray.push(text_truncate(collection.name, 10));
    } else {
      collectionChartData[4] += collection.count;
      collectionLabelsArray[4] = "Other";
    }
  });

  const chartOptions1 = {
    dataLabels: {
      enabled: false,
    },
    colors: ["#507255", "#488B49", "#4AAD52", "#6EB257", "#001514"],
    chart: {
      id: "recognitions-donut",
      fontFamily: "Rubik",
    },
    labels: labelsArray,
    plotOptions: {
      pie: {
        customScale: 1.1,
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let total = 0;
        for (let x of series) {
          total += x;
        }
        let selected = series[seriesIndex];
        return (
          '<div class="p-2">' +
          w.config.labels[seriesIndex] +
          ": " +
          ((selected / total) * 100).toFixed(0) +
          "%" +
          " </div>"
        );
      },
    },
  };

  const chartOptions2 = {
    dataLabels: {
      enabled: false,
    },
    colors: ["#507255", "#488B49", "#4AAD52", "#6EB257", "#001514"],
    chart: {
      id: "collections-donut",
      fontFamily: "Rubik",
    },
    labels: collectionLabelsArray,
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let total = 0;
        for (let x of series) {
          total += x;
        }
        let selected = series[seriesIndex];
        return (
          '<div class="p-2">' +
          w.config.labels[seriesIndex] +
          ": " +
          ((selected / total) * 100).toFixed(0) +
          "%" +
          " </div>"
        );
      },
    },
  };

  return (
    <>
      <ReactTooltip effect="solid" className="rounded-md" />
      <section className="pb-6 pt-2">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0 animate-enter transition-all">
            <div className="h-full px-8 pt-5 pb-8 bg-slate-50 shadow rounded-md">
              <>
                <div className="flex items-center mb-4">
                  <h3 className="mr-auto pr-1 text-md text-darkBlueGray-300 font-heading font-medium">
                    Tags used
                    <InformationCircleIcon
                      data-tip="Times all tags have been used in recognitions"
                      className="h-4 w-4 float-right ml-2 cursor-pointer mt-1 text-gray-500"
                      aria-hidden="true"
                    />
                  </h3>
                  <div className="px-3 py-1 text-center bg-blue-200 bg-opacity-70 rounded-full">
                    <span className="text-sm font-bold text-blue-500 leading-6">
                      All time
                    </span>
                  </div>
                </div>
                <div className="flex-col text-center items-center mt-12 mb-5">
                  <>
                    <div className="block text-6xl font-heading font-medium">
                      {tagsData.totalRecognitions}
                    </div>
                    <div className="block text-2xl font-heading text-gray-700 font-medium">
                      times used
                    </div>
                  </>
                </div>
              </>
            </div>
          </div>
          {tagsData.totalRecognitions > 0 && (
            <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0 animate-enter transition-all">
              <div className="h-full px-8 py-8 bg-slate-50 shadow rounded-md">
                <>
                  <div className="flex items-center mb-4">
                    <h3 className="mr-auto pr-1 text-md text-darkBlueGray-300 font-heading font-medium">
                      Tag distribution
                      <InformationCircleIcon
                        data-tip="Distribution of tags in sent recognitions"
                        className="h-4 w-4 float-right ml-2 cursor-pointer mt-1 text-gray-500"
                        aria-hidden="true"
                      />
                    </h3>
                  </div>
                  <div
                    className="flex items-center cursor-pointer"
                    id="recog-chartcontainer"
                  >
                    <Chart
                      series={chartData}
                      type="donut"
                      chartOptions={chartOptions1}
                      options={chartOptions1}
                    />
                  </div>
                </>
              </div>
            </div>
          )}
          {tagsData.totalRecognitions > 0 && (
            <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0 animate-enter transition-all">
              <div className="h-full px-8 py-8 bg-slate-50 shadow rounded-md">
                <>
                  <div className="flex items-center mb-4">
                    <h3 className="mr-auto pr-1 text-md text-darkBlueGray-300 font-heading font-medium">
                      Collection distribution
                      <InformationCircleIcon
                        data-tip="Distribution of collections in sent recognitions"
                        className="h-4 w-4 float-right ml-2 cursor-pointer mt-1 text-gray-500"
                        aria-hidden="true"
                      />
                    </h3>
                  </div>
                  <div className="flex items-center cursor-pointer">
                    <Chart
                      series={collectionChartData}
                      type="donut"
                      chartOptions={chartOptions2}
                      options={chartOptions2}
                    />
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default TagsStatsBoxes;
