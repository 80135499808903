import { Link } from "react-router-dom";

const TrialSubNotification = ({ subscription }) => {
  if (
    subscription.isFreeTrial ||
    subscription.saasSubscriptionStatus === "Trialing"
  ) {
    return (
      <Link to="/account">
        <div className="relative bg-yellow-400">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-normal text-white">
                <span className="hidden md:inline">
                  {" "}
                  You are currently in free trial, click here to upgrade now and
                  start planting real trees 🌳
                </span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  } else if (
    !subscription.isFreeTrial &&
    subscription.saasSubscriptionStatus === "Unsubscribed"
  ) {
    return (
      <Link to="/account">
        <div className="relative bg-red-700">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-normal text-white">
                <span className="hidden md:inline">
                  Your trial has ended and you have no active subscription,
                  click here to upgrade now and start planting real trees 🌳
                </span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
};

export default TrialSubNotification;
