import { useState } from "react";
import { Switch } from "@headlessui/react";
import { state, useSnapshot, getCurrentUser } from "../../state/stateStore";
import { CogIcon, BellIcon, TrashIcon } from "@heroicons/react/outline";
import * as api from "../../services/api";
import { createToast } from "../Helpers/toaster";
import { LoadingIndicator } from "../Helpers/helpers";

// Subcomponents
import ImpactDashboard from "./ImpactDashboard";
import MarketingKit from "./MarketingKit";
import Company from "./Company";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Company" },
  { name: "Impact Page" },
  { name: "Marketing Kit" },
];

const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);

  const changeTab = (event) => {
    setTabIndex(event.target.value);
  };

  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const isLoading = snap.isLoading;
  const currentTenant = snap.currentTenant;

  if (currentUser) {
    return (
      <div className="animate-fade">
        <section className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container mx-auto my-8">
              <div className="flex flex-col">
                <h2 className="text-2xl flex inline-flex font-semibold text-slate-600 leading-relaxed">
                  <CogIcon
                    className="float-left h-8 w-8 mt-0.5 mr-2"
                    aria-hidden="true"
                  />{" "}
                  Settings {isSwitchLoading && <LoadingIndicator />}
                </h2>
              </div>
              <div className="p-5 mt-6 max-w-4xl shadow rounded-md bg-slate-50">
                <div>
                  <div>
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                      General
                    </h2>
                    <p className="mt-1 text-sm text-gray-600">
                      You can change your personal Evergreen app related
                      settings here.
                    </p>
                  </div>
                  <ul role="list" className="mt-2 divide-y divide-gray-200">
                    <li className="py-4 flex items-center justify-between">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <TrashIcon
                            className="h-6 w-6 mr-3 mt-2 text-gray-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-medium text-gray-900">
                            Request data deletion
                          </p>
                          <p className="text-sm text-gray-600">
                            If you want to delete your personal data, send us a
                            request.
                          </p>
                        </div>
                      </div>
                      <a
                        href="mailto:aleksi@evergreen.so?subject=Evergreen data deletion request"
                        target="_blank"
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Request
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {currentUser.roles.includes("admin") && currentTenant && (
                <div className="p-5 mt-6 max-w-4xl shadow rounded-md bg-slate-50">
                  <div>
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                      Admin
                    </h2>
                    <p className="mt-1 text-sm text-gray-600">
                      As the workspace or enterprise admin you can change
                      various organizational settings.
                    </p>
                  </div>
                  <div className="mt-2 mb-4">
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primaryGreen focus:border-primaryGreen sm:text-sm rounded-md"
                        defaultValue={tabIndex}
                        onChange={changeTab}
                      >
                        {tabs.map((tab, i) => (
                          <option key={i} value={i}>
                            {tab.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-200">
                        <nav
                          className="-mb-px flex space-x-8"
                          aria-label="Tabs"
                        >
                          {tabs.map((tab, i) => (
                            <a
                              key={tab.name}
                              className={classNames(
                                tabIndex === i
                                  ? "border-primaryGreen text-primaryGreen"
                                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                              )}
                              aria-current={tabIndex === i ? "page" : undefined}
                              onClick={() => setTabIndex(i)}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                  {tabIndex === 0 && <Company currentTenant={currentTenant} />}
                  {tabIndex === 1 && (
                    <ImpactDashboard currentTenant={currentTenant} />
                  )}
                  {tabIndex === 2 && <MarketingKit />}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Settings;
