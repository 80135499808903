import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const stories = [
  {
    name: "George’s story: promoting food security through agroforestry",
    url: "/stories/george.png",
    story:
      "Through Eden Projects agroforestry program, employees grow an assortment of trees and crops that, in turn, produce direct economic benefits, such as food and medicine, in addition to environmental benefits. In Kenya, this program would not be possible without Agroforestry Manager, George Were. Before joining Kenya team, George was teaching his community how to farm and support their forests. As Agroforestry Manager, George identifies indigenous species to plant, and works with local farmers to provide agricultural training.\n\nHe now plans to enroll farmers in a vegetable program, in which we will grow vegetable seedlings at the nursery and distribute them to farmers to harvest on their land.",
  },
  {
    name: "Vavy’s story: the power of economic self-sufficiency",
    url: "/stories/vavy.png",
    story:
      "With limited income, Maman’i Taba (Vavy) had to choose between sending her four children to school or feeding her extended family of ten. She was selling produce at the local market, but the money she earned was not enough to provide for her family’s needs. She felt the pressure of providing for ten people and knew something needed to change. Amid her struggle, Vavy was introduced to Eden Projects Madagascar team and hired as a Planter, where she selects and collects seeds.\n\nSince joining Edens Madagascar team, Vavy’s children are back in school, she is saving for a new home big enough to accommodate her family, and she can see the environment around her healing.",
  },
  {
    name: "Nima’s story: overcoming Nepal’s lockdown",
    url: "/stories/nima.png",
    story:
      "During the COVID-19 pandemic, Nima Dolma Lama lost her hotel business and found herself not knowing how she would survive. All she had left to rely on was her family’s small farm, which did not yield a sufficient amount of crops year-round, to support her husband, two sons, two daughters-in-law, grandson, and herself. With no formal education and gender inequality prevalent in Nepal, on top of many businesses closed due to the pandemic lockdown, there were very limited employment opportunities available for Nima. However, this did not stop her. She continued to face these obstacles head-on, leading her to connect with Eden Projects Nepal team.\n\nNow, Nima has a full-time position at Eden Projects Mude nursery. With the consistent income she earns, she can afford her family’s daily household expenses.",
  },
];

export default function StoryDialog(props) {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => props.setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 translate-y-8"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95 translate-y-8"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-md bg-white/80 backdrop-blur-xl px-6 pb-6 border-2 border-black text-left align-middle shadow-xl transition-all">
                <div
                  className="absolute top-0 right-0 p-4 cursor-pointer"
                  onClick={() => props.setOpen(false)}
                >
                  <svg
                    className="w-4 h-4 text-black"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="mt-8">
                  <div className="grid grid-cols-6 gap-8 mb-4">
                    <div className="col-span-3">
                      <img
                        src={stories[props.storyIndex].url}
                        className="w-full rounded-md border-2 border-black"
                        alt={stories[props.storyIndex].name}
                      />
                    </div>
                    <div className="col-span-3">
                      <h3 className="text-3xl font-headline font-semibold">
                        {" "}
                        {stories[props.storyIndex].name}
                      </h3>
                      <p className="whitespace-pre-line text-md mt-6 text-gray-900">
                        {" "}
                        {stories[props.storyIndex].story}
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
