import { useState, useRef } from "react";
import { Transition } from "@headlessui/react";
import {
  OfficeBuildingIcon,
  PhotographIcon,
  PencilAltIcon,
  SaveIcon,
  GlobeIcon,
} from "@heroicons/react/outline";
import * as api from "../../services/api";
import { createToast } from "../Helpers/toaster";
import { getCurrentTenant } from "../../state/stateStore";
import UploadPicModal from "./UploadPicModal";

const Company = ({ currentTenant }) => {
  const [nameEdit, setNameEdit] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const ref = useRef(null);

  const closeModal = (notifyUpload) => {
    setIsUploadOpen(false);
    if (notifyUpload) {
      getCurrentTenant();
      createToast("IMAGE_UPLOADED");
    }
  };

  const openModal = () => {
    setIsUploadOpen(true);
  };

  const updateCompanyName = async () => {
    const companyName = ref.current.value;
    if (companyName) {
      await api.updateCompanyName(companyName);
      createToast("COMPANY_NAME_UPDATED");
      await getCurrentTenant();
      setNameEdit(false);
    }
  };

  const comp_name = currentTenant.company_name
    ? currentTenant.company_name
    : currentTenant.display_name;

  return (
    <>
      <UploadPicModal
        isOpen={isUploadOpen}
        closeModal={closeModal}
        getCurrentTenant={getCurrentTenant}
      />
      <div className="mt-4">
        <p className="mt-1 text-sm text-gray-600">
          Modify your company/organization details here.
        </p>

        <ul role="list" className="mt-2 divide-y divide-gray-200">
          <li className="py-4 flex items-center justify-between">
            <div className="flex basis-1/2">
              <div className="flex-shrink-0">
                <OfficeBuildingIcon
                  className="h-6 w-6 mr-3 mt-2 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">
                  Company name
                </p>
                <p className="text-sm text-gray-600">
                  Your company name, visible on Impact Page.
                </p>
              </div>
            </div>
            <div className="flex basis-1/3 mr-2">
              {!nameEdit && (
                <p className="text-md text-center ml-6 font-semibold">
                  {comp_name}
                </p>
              )}
              {nameEdit && (
                <div className="w-full">
                  <input
                    type="name"
                    name="company-name"
                    id="company-name"
                    ref={ref}
                    className="shadow-sm p-3 focus:ring-primaryGreen focus:border-primaryGreen block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Acme Inc"
                    defaultValue={comp_name}
                  />
                </div>
              )}
            </div>
            <div className="flex">
              {!nameEdit && (
                <button
                  type="button"
                  className="inline-flex ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-orange-700 bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  onClick={() => setNameEdit(true)}
                >
                  <PencilAltIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Edit
                </button>
              )}
              {nameEdit && (
                <button
                  type="button"
                  className="inline-flex ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  onClick={() => updateCompanyName()}
                >
                  <SaveIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Update
                </button>
              )}
            </div>
          </li>
          <li className="py-4 flex items-center justify-between">
            <div className="flex basis-1/2">
              <div className="flex-shrink-0">
                <PhotographIcon
                  className="h-6 w-6 mr-3 mt-2 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">
                  Company picture
                </p>
                <p className="text-sm text-gray-600">
                  Upload your organization picture (logo or similar.) - it will
                  be visible on your Impact page The logo should be 1:1 and
                  maximum of 1000px x 1000px.
                </p>
              </div>
            </div>
            <Transition
              className="flex basis-1/3 mt-4"
              appear={true}
              show={true}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <img
                src={
                  currentTenant.tenant_picture_uri
                    ? currentTenant.tenant_picture_uri
                    : process.env.PUBLIC_URL + "/default-placeholder.png"
                }
                className="inline-block h-24 ml-10  rounded-md"
                alt="Company logo"
              />
            </Transition>
            <div className="flex">
              <button
                type="button"
                onClick={() => openModal()}
                className="inline-flex whitespace-nowrap ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-orange-700 bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Upload picture
              </button>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Company;
