/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { state, useSnapshot } from "../state/stateStore";
import { NavLink, useLocation } from "react-router-dom";
import {
  HeartIcon,
  ChartBarIcon,
  HomeIcon,
  MenuIcon,
  XIcon,
  CreditCardIcon,
  TagIcon,
  SupportIcon,
  CogIcon,
  ChartSquareBarIcon,
  LogoutIcon,
} from "@heroicons/react/outline";

import { getProfilePictureURI } from "./Helpers/helpers";
import TrialSubNotification from "./Helpers/TrialSubNotification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavItems = (props) => {
  const pathname = props.pathname;
  return (
    <>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
            : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        }
        to="/dashboard"
      >
        <HomeIcon
          className={classNames(
            pathname === "/dashboard" ? "text-primaryGreen" : "text-gray-600",
            "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
        Dashboard
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
            : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        }
        to="/recognitions"
      >
        <HeartIcon
          className={classNames(
            pathname === "/recognitions"
              ? "text-primaryGreen"
              : "text-gray-600",
            "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
        Recognitions
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
            : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        }
        to="/reporting"
      >
        <ChartBarIcon
          className={classNames(
            pathname === "/reporting" ? "text-primaryGreen" : "text-gray-600",
            "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
        Reporting
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
            : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        }
        to="/tags"
      >
        <TagIcon
          className={classNames(
            pathname.includes("/tags") ? "text-primaryGreen" : "text-gray-600",
            "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
        Tags
      </NavLink>
      <a
        className="text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        href="https://evergreen-app.notion.site/Help-Center-defd1351f1a64b6fabe5040f1c2697a5"
        target="_blank"
        rel="noreferrer"
      >
        <SupportIcon
          className="group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
          aria-hidden="true"
        />
        Support
      </a>
      {props.currentTenant &&
        props.currentTenant.settings.impact_dashboard_enabled && (
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium animate-enter rounded-md"
            }
            to={"/impact/" + props.currentTenant.display_name}
            target="_blank"
          >
            <ChartSquareBarIcon
              className={classNames(
                pathname.includes("/impact")
                  ? "text-primaryGreen"
                  : "text-gray-600",
                "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
            Impact
          </NavLink>
        )}
    </>
  );
};

const WithNavigation = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleLogOut = () => {
    const authUrl = process.env.REACT_APP_BACKEND_URL + "/auth/logout";
    window.open(authUrl, "_self");
  };

  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTenant = snap.currentTenant;
  const { pathname } = useLocation();

  if (currentUser && currentTenant) {
    return (
      <>
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 flex z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full  bg-gradient-to-b from-coolBackground1 to-coolBackground2">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex flex-shrink-0 px-4">
                      <img
                        className="h-8 w-auto"
                        src={
                          process.env.PUBLIC_URL + "/evergreen-text-logo.svg"
                        }
                        alt="Evergreen"
                      />
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      <NavItems
                        pathname={pathname}
                        currentUser={currentUser}
                        currentTenant={currentTenant}
                      />
                    </nav>
                  </div>
                  <div className="flex-shrink-0 flex-col px-2 py-2">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      }
                      to="/settings"
                    >
                      <CogIcon
                        className={classNames(
                          pathname.includes("/settings")
                            ? "text-primaryGreen"
                            : "text-gray-600",
                          "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Settings
                    </NavLink>
                    {currentUser.roles.includes("admin") && (
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        }
                        to="/account"
                      >
                        <CreditCardIcon
                          className={classNames(
                            pathname.includes("/account")
                              ? "text-primaryGreen"
                              : "text-gray-600",
                            "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        Account
                      </NavLink>
                    )}
                    <a
                      className="text-textColor cursor-pointer hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      onClick={() => handleLogOut()}
                    >
                      <LogoutIcon
                        className={classNames(
                          pathname.includes("/logout")
                            ? "text-primaryGreen"
                            : "text-gray-600",
                          "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Log out
                    </a>
                  </div>
                  <div className="flex-shrink-0 flex p-4 pt-2 pb-6">
                    <a className="flex-shrink-0 w-full group block">
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-9 w-9 rounded-full"
                            src={getProfilePictureURI(currentUser.picture_uri)}
                            alt={currentUser.name}
                          />
                        </div>
                        <div className="ml-3 flex">
                          <div>
                            <p className="text-sm font-medium text-textColor">
                              {currentUser.name}
                            </p>
                            <p className="text-xs  text-textColor">
                              {currentTenant.company_name
                                ? currentTenant.company_name
                                : currentTenant.display_name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 bg-gradient-to-b from-coolBackground1 to-coolBackground2">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex flex-shrink-0 px-4">
                  <img
                    className="h-8 w-auto"
                    src={process.env.PUBLIC_URL + "/evergreen-text-logo.svg"}
                    alt="Evergreen"
                  />
                </div>
                <nav className="mt-6 flex-1 px-2 space-y-1">
                  <NavItems
                    pathname={pathname}
                    currentUser={currentUser}
                    currentTenant={currentTenant}
                  />
                </nav>
              </div>
              <div className="flex-shrink-0 flex-col px-2 py-2">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  }
                  to="/settings"
                >
                  <CogIcon
                    className={classNames(
                      pathname.includes("/settings")
                        ? "text-primaryGreen"
                        : "text-gray-600",
                      "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Settings
                </NavLink>
                {currentUser.roles.includes("admin") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "bg-white text-primaryGreen shadow-sm group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        : "text-textColor hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    }
                    to="/account"
                  >
                    <CreditCardIcon
                      className={classNames(
                        pathname.includes("/account")
                          ? "text-primaryGreen"
                          : "text-gray-600",
                        "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    Account
                  </NavLink>
                )}
                <a
                  className="text-textColor cursor-pointer hover:bg-white hover:text-primaryGreen hover:bg-opacity-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  onClick={() => handleLogOut()}
                >
                  <LogoutIcon
                    className={classNames(
                      pathname.includes("/logout")
                        ? "text-primaryGreen"
                        : "text-gray-600",
                      "group-hover:text-primaryGreen mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Log out
                </a>
              </div>
              <div className="flex-shrink-0 flex p-4 pt-2 pb-6">
                <a className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={getProfilePictureURI(currentUser.picture_uri)}
                        alt={currentUser.name}
                      />
                    </div>
                    <div className="ml-3 flex">
                      <div>
                        <p className="text-sm font-medium text-textColor">
                          {currentUser.name}
                        </p>
                        <p className="text-xs  text-textColor">
                          {currentTenant.company_name
                            ? currentTenant.company_name
                            : currentTenant.display_name}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="md:pl-64 flex flex-col flex-1">
            <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-coolBackground2">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-700 hover:text-primaryGreen focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryGreen"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <main className="flex-1">
              {currentUser.roles.includes("admin") &&
                currentTenant.subscription.saasSubscriptionStatus !==
                  "Subscribed" && (
                  <TrialSubNotification
                    subscription={currentTenant.subscription}
                  />
                )}
              {children}
            </main>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default WithNavigation;
