import React from "react";
import { useSearchParams } from "react-router-dom";

const Landing = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleLoginWithTeams = () => {
    const authUrl =
      process.env.REACT_APP_BACKEND_URL + "/auth/login?token=" + token;
    window.open(authUrl);
  };

  return (
    <div className="h-screen flex bg-coolBackground1 animate-fade">
      <div className="w-full max-w-xl m-auto bg-white content-center rounded-3xl text-center shadow-sm py-10 px-16 xs:max-w-sm">
        <img
          src="/favicon.png"
          alt="Welcome to Evergreen"
          className="mx-auto rounded-3xl h-32 w-32 text-center mb-6"
        />
        <h1 className="text-2xl font-medium text-primary mt-4 text-center">
          Activate Evergreen
        </h1>
        <p className="font-light text-mutedTextColor mt-4 text-center">
          Please sign in with your Microsoft Account to activate Evergreen
          subscription.
        </p>
        <img
          src="/sign-in-with-ms.svg"
          alt="Sign in with Microsoft"
          className="mt-8 cursor-pointer mx-auto"
          onClick={() => handleLoginWithTeams()}
        />
      </div>
    </div>
  );
};

export default Landing;
