import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { CollectionIcon } from "@heroicons/react/outline";
import { getCurrentTenant } from "../../state/stateStore";
import * as api from "../../services/api";

const CollectionModal = (props) => {
  const [collectionName, setCollectionName] = useState("");

  useEffect(() => {
    if (props.tagCollection) {
      setCollectionName(props.tagCollection.collection_name);
    } else {
      setCollectionName("");
    }
  }, [props.tagCollection]);

  const createCollection = async () => {
    if (collectionName.length > 0) {
      let res = await api.createTenantTagCollection(collectionName);
      if (res === "OK") {
        getCurrentTenant();
        props.closeModal();
        setCollectionName("");
      }
    }
  };

  const editCollection = async () => {
    if (collectionName.length > 0) {
      let res = await api.editTenantTagCollection(
        collectionName,
        props.tagCollection._id
      );
      if (res === "OK") {
        getCurrentTenant();
        props.closeModal();
        setCollectionName("");
      }
    }
  };

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <CollectionIcon
                    className="float-left h-4 w-4 mt-1 mr-2"
                    aria-hidden="true"
                  />{" "}
                  {props.tagCollection ? (
                    <div>
                      Edit collection - {props.tagCollection.collection_name}
                    </div>
                  ) : (
                    <div>Create a tag collection</div>
                  )}
                </Dialog.Title>
                <p className="text-sm mt-2 text-gray-600">
                  Tag collection is a collection which host multple tags, you
                  can create or edit a tag collection here.
                </p>
                <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="tag_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Collection name
                    </label>
                    <div className="mt-1 flex w-full rounded-md shadow-sm">
                      <input
                        type="text"
                        name="collection_name"
                        id="collection_name"
                        required
                        placeholder="For example Skills.."
                        value={collectionName}
                        onChange={(e) => setCollectionName(e.target.value)}
                        className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                    onClick={() => props.closeModal()}
                  >
                    Cancel
                  </button>
                  {props.tagCollection && (
                    <button
                      type="button"
                      className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-amber-100 px-4 py-2 text-sm font-medium text-amber-900 hover:bg-amber-200 focus-visible:ring-2 focus-visible:ring-amber-500 focus-visible:ring-offset-2"
                      onClick={() => editCollection()}
                    >
                      Save collection
                    </button>
                  )}
                  {!props.tagCollection && (
                    <button
                      type="button"
                      className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => createCollection()}
                    >
                      Create collection
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CollectionModal;
