import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useEffect, useState } from "react";
import { TagIcon } from "@heroicons/react/outline";
import data from "@emoji-mart/data";
import { Picker } from "emoji-mart";
import { getCurrentTenant } from "../../state/stateStore";
import * as api from "../../services/api";

const TagModal = (props) => {
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [tagName, setTagName] = useState("");

  const EmojiPicker = (props) => {
    const ref = useRef();

    useEffect(() => {
      new Picker({ ...props, data, theme: "light", ref });
    }, []);

    return <div ref={ref} />;
  };

  useEffect(() => {
    if (props.tag) {
      setTagName(props.tag.name);
      setSelectedEmoji({ native: props.tag.emoji });
    } else {
      setTagName("");
      setSelectedEmoji(null);
    }
  }, [props.tag]);

  const createTag = async () => {
    if (selectedEmoji && tagName.length > 0) {
      let res = await api.createTenantTag(
        tagName,
        selectedEmoji.native,
        props.tagCollection._id
      );
      if (res === "OK") {
        getCurrentTenant();
        props.closeModal();
        setSelectedEmoji(null);
        setTagName("");
      }
    }
  };

  const editTag = async () => {
    if (selectedEmoji && tagName.length > 0) {
      let res = await api.editTenantTag(
        props.tag._id,
        tagName,
        selectedEmoji.native
      );
      if (res === "OK") {
        getCurrentTenant();
        props.closeModal();
        setSelectedEmoji(null);
        setTagName("");
      }
    }
  };

  if (props.tagCollection || props.tag) {
    return (
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <TagIcon
                      className="float-left h-4 w-4 mt-1 mr-2"
                      aria-hidden="true"
                    />{" "}
                    {props.tag ? (
                      <div>Edit tag {props.tag.name}</div>
                    ) : (
                      <div>
                        {" "}
                        Add new tag to {props.tagCollection.collection_name}
                      </div>
                    )}
                  </Dialog.Title>
                  <p className="text-sm mt-2 text-gray-600">
                    {props.tag ? "Edit the selected tag" : "Add a new tag"}. Tag
                    must have a name and an emoji.
                  </p>
                  <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="tag_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tag name
                      </label>
                      <div className="mt-1 flex w-full rounded-md shadow-sm">
                        <input
                          type="text"
                          name="tag_name"
                          id="tag_name"
                          required
                          value={tagName}
                          onChange={(e) => setTagName(e.target.value)}
                          className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="emoji"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tag emoji
                      </label>
                      <div className="mt-2">
                        {selectedEmoji && (
                          <div>
                            <span className="text-5xl float-left">
                              {selectedEmoji.native}
                            </span>
                            <button
                              type="button"
                              onClick={() => setSelectedEmoji(null)}
                              className="ml-4 float-left mt-2 bg-white py-1 px-1.5 rounded-md border border-transparent bg-red-100 px-4 py-2 text-xs font-medium text-red-900 hover:bg-red-200 focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            >
                              Change
                            </button>
                          </div>
                        )}
                        {!selectedEmoji && (
                          <EmojiPicker onEmojiSelect={setSelectedEmoji} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                      onClick={() => props.closeModal()}
                    >
                      Cancel
                    </button>
                    {props.tag && (
                      <button
                        type="button"
                        className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => editTag()}
                      >
                        Save tag
                      </button>
                    )}
                    {!props.tag && (
                      <button
                        type="button"
                        className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => createTag()}
                      >
                        Add tag
                      </button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  } else return null;
};

export default TagModal;
