import axios from "axios";
axios.default.withCredentials = true;

/*                                */
/*      General API routes        */
/*                                */

// Get logged in user data from Evergreen backend

export const getCurrentUser = async () => {
  const url = "/api/user/current";
  return axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get logged in user with recognition data

export const getCurrentUserRecognitions = async () => {
  const url = "/api/user/current/recognitions";
  return axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get viewable user with userId

export const getViewableUser = async (userId) => {
  const url = "/api/user";
  return axios.post(url, { userId: userId }).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get tenant basic data from backend

export const getCurrentTenant = async () => {
  const url = "/api/tenant";
  return axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get tenant users from backend

export const getCurrentTenantUsers = async () => {
  const url = "/api/tenant/users";
  return axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get tenant data for date-range based reporting from backend

export const getTenantDataForReporting = async (startDate, endDate) => {
  const url = `/api/tenant/reporting/date`;
  return axios
    .post(url, { start_date: startDate, end_date: endDate })
    .then((res) => {
      return res.data;
    });
};

// Get tenant data for monthly recognitions reporting

export const getTenantRecognitionsMonthly = async (month, year) => {
  const url = `/api/tenant/reporting/recognitions`;
  return axios.post(url, { month: month, year: year }).then((res) => {
    return res.data;
  });
};

/*                                */
/*     Tag and Tag collections    */
/*                                */

// Get tag collections

export const getTenantTagCollections = async () => {
  const url = `/api/tenant/tag-collections`;
  return axios.get(url).then((res) => {
    return res.data;
  });
};

// Create tag collection

export const createTenantTagCollection = async (collection_name) => {
  const url = `/api/tenant/tag-collections/create`;
  return axios.post(url, { collection_name: collection_name }).then((res) => {
    return res.data;
  });
};

// Edit tag collection

export const editTenantTagCollection = async (
  collection_name,
  collection_id
) => {
  const url = `/api/tenant/tag-collections/update`;
  return axios
    .post(url, {
      collection_name: collection_name,
      collection_id: collection_id,
    })
    .then((res) => {
      return res.data;
    });
};

// Delete tag collection

export const deleteTenantTagCollection = async (collectionId) => {
  const url = `/api/tenant/tag-collections/delete`;
  return axios.post(url, { collection_id: collectionId }).then((res) => {
    return res.data;
  });
};

// Create tag

export const createTenantTag = async (name, emoji, collection_id) => {
  const url = `/api/tenant/tags/create`;
  return axios
    .post(url, { name: name, emoji: emoji, collection_id: collection_id })
    .then((res) => {
      return res.data;
    });
};

// Edit tag

export const editTenantTag = async (tagId, name, emoji) => {
  const url = `/api/tenant/tags/update`;
  return axios
    .post(url, { tag_id: tagId, name: name, emoji: emoji })
    .then((res) => {
      return res.data;
    });
};

// Delete tag

export const deleteTenantTag = async (tagId) => {
  const url = `/api/tenant/tags/delete`;
  return axios.post(url, { tag_id: tagId }).then((res) => {
    return res.data;
  });
};

// Get viewable tag
export const getViewableTag = async (tagId, startDate, endDate) => {
  const url = `/api/tenant/tags/view`;
  return axios
    .post(url, { tag_id: tagId, start_date: startDate, end_date: endDate })
    .then((res) => {
      return res.data;
    });
};

// Get tag recognitions by month and year

export const getTagRecognitionsMonthly = async (month, year, tagId) => {
  const url = `/api/tenant/tags/recognitions`;
  return axios
    .post(url, { month: month, year: year, tag_id: tagId })
    .then((res) => {
      return res.data;
    });
};

/*                                */
/*     Manage users API           */
/*                                */

export const updateUserRoles = async (action, userId) => {
  const url = `/api/tenant/users/update`;
  return axios.post(url, { action: action, user_id: userId }).then((res) => {
    return res.data;
  });
};

/*                                */
/*     Settings api               */
/*                                */

// Update tenant name

export const updateCompanyName = async (companyName) => {
  const url = `/api/tenant/settings/update`;
  return axios
    .post(url, { action: "UPDATE_NAME", company_name: companyName })
    .then((res) => {
      return res.data;
    });
};

// Toggle impact dashboard

export const toggleImpactDash = async (boolean) => {
  const url = `/api/tenant/settings/update`;
  return axios
    .post(url, { action: "TOGGLE_IMPACT_DASHBOARD", toggle_state: boolean })
    .then((res) => {
      return res.data;
    });
};

// Update impact statement

export const updateImpactStatement = async (statement) => {
  const url = `/api/tenant/settings/update`;
  return axios
    .post(url, {
      action: "UPDATE_IMPACT_STATEMENT",
      impact_statement: statement,
    })
    .then((res) => {
      return res.data;
    });
};

/*                                */
/*     SaaS billing API           */
/*                                */

export const updateSubscription = async (action, subscriptionId) => {
  const url = `/api/tenant/subscription/update`;
  return axios
    .post(url, { action: action, subscription_id: subscriptionId })
    .then((res) => {
      return res.data;
    });
};
