import { state, useSnapshot } from "../../state/stateStore";
import { CreditCardIcon } from "@heroicons/react/outline";
import { UserGroupIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import * as api from "../../services/api";

const Account = () => {
  const navigate = useNavigate();

  // State
  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTenant = snap.currentTenant;
  const isLoading = snap.isLoading;

  if (!isLoading && currentUser && currentTenant) {
    return (
      <>
        <section className="py-6 animate-fade">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container mx-auto my-8">
              <div className="flex flex-col">
                <h2 className="text-2xl flex inline-flex font-semibold text-slate-600 leading-relaxed">
                  <CreditCardIcon
                    className="float-left h-8 w-8 mt-0.5 mr-2"
                    aria-hidden="true"
                  />{" "}
                  Account
                </h2>
                <p className="text-slate-600">
                  Manage your users and Evergreen subscription
                </p>
              </div>
              <section className="py-8">
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full lg:w-1/3  mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-md text-gray-600 font-heading font-medium">
                          My organization
                        </h3>
                      </div>
                      <div className="flex items-center mb-1">
                        <span className="inline-block mr-2 text-2xl font-heading font-medium">
                          {currentTenant.display_name}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="inline-block mr-2 mt-2 text-xl font-normal">
                          {currentTenant.amount_users} active users
                        </span>
                      </div>
                      <div className="flex">
                        <button
                          className="float-left inline-flex mt-5 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          onClick={() => navigate("/account/users/manage")}
                        >
                          <UserGroupIcon
                            className="float-left h-4 w-4 -mt-0.5 mr-2"
                            aria-hidden="true"
                          />{" "}
                          Manage users
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/3 mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-md text-gray-600 font-heading font-medium">
                          Subscription
                        </h3>
                      </div>
                      <div className="flex flex-col mb-1">
                        {currentTenant.subscription.saasSubscriptionStatus ===
                          "Subscribed" &&
                          !currentTenant.subscription.isFreeTrial && (
                            <>
                              <div className="block mr-2 text-2xl font-heading text-primaryGreen font-medium">
                                Active 🌱
                              </div>
                              <div className="block mr-2 text-md mt-2 font-normal">
                                Thank you for subscribing. 💚 Your plan is now
                                active.
                              </div>
                            </>
                          )}
                        {currentTenant.subscription.saasSubscriptionStatus ===
                          "Trialing" &&
                          currentTenant.subscription.isFreeTrial && (
                            <span className="inline-block mr-2 text-2xl font-heading text-yellow-400 font-medium">
                              Trial
                            </span>
                          )}
                        {currentTenant.subscription.saasSubscriptionStatus ===
                          "Unsubscribed" && (
                          <>
                            <div className="block mr-2 text-2xl font-heading text-orange-700 font-medium">
                              No subscription
                            </div>
                            <div className="block mr-2 text-md mt-2 font-normal">
                              Your trial period has ended or you have
                              unsubscribed. Thank you fo trying out Evergreen,
                              please subscribe to continue planting trees. 🌳
                            </div>
                          </>
                        )}
                        {currentTenant.subscription.saasSubscriptionStatus ===
                          "Suspended" && (
                          <span className="inline-block mr-2 text-2xl font-heading text-red-700 font-medium">
                            Suspended
                          </span>
                        )}
                      </div>
                      <div className="flex">
                        {currentTenant.subscription.saasSubscriptionStatus ===
                          "Unsubscribed" && (
                          <a
                            className="float-left inline-flex mt-5 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            href="https://appsource.microsoft.com/en-us/product/web-apps/stonksoy1639996807147.evergreen?tab=Overview"
                          >
                            Resubscribe
                          </a>
                        )}
                        {currentTenant.subscription.saasSubscriptionStatus ===
                          "Trialing" && (
                          <a
                            className="float-left inline-flex mt-5 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            href="https://appsource.microsoft.com/en-us/product/web-apps/stonksoy1639996807147.evergreen?tab=Overview"
                          >
                            Subscribe
                          </a>
                        )}
                        {currentTenant.subscription.saasSubscriptionStatus ===
                          "Subscribed" && (
                          <button
                            className="float-left mt-5 text-xs text-red-700"
                            onClick={async () => {
                              const res = await api.updateSubscription(
                                "UNSUBSCRIBE",
                                currentTenant.subscription.id
                              );
                              if (res) {
                                alert(
                                  "You have unsubscribed succesfully, it will take a few minutes for the changes to take effect."
                                );
                              }
                            }}
                          >
                            End subscription
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Account;
