import ReactTooltip from "react-tooltip";

const StatsBoxes = (props) => {
  // Loop through stats to calculate monthly increases.
  let recognitionGivers60d = 0;
  let recognitionSent60d = 0;
  let treesPlanted60d = 0;

  const reportingData60d = props.reportingData60d;
  const reportingDataMonth = props.reportingDataMonth;

  reportingData60d.forEach((member) => {
    if (member.feedback_given > 0) {
      recognitionGivers60d++;
    }
    recognitionSent60d = recognitionSent60d + member.feedback_given;
    treesPlanted60d = treesPlanted60d + member.trees_planted;
  });

  let recognitionGivers = 0;
  let recognitionSent = 0;
  let treesPlanted = 0;

  reportingDataMonth.forEach((member) => {
    if (member.feedback_given > 0) {
      recognitionGivers++;
    }
    recognitionSent = recognitionSent + member.feedback_given;
    treesPlanted = treesPlanted + member.trees_planted;
  });

  let recognitionGiversGrowth = 0;
  let recognitionSentGrowth = 0;
  let treesPlantedGrowth = 0;

  if (recognitionGivers60d > 0) {
    recognitionGiversGrowth =
      ((recognitionGivers - recognitionGivers60d) / recognitionGivers60d) * 100;
  }

  if (recognitionSent60d > 0) {
    recognitionSentGrowth =
      ((recognitionSent - recognitionSent60d) / recognitionSent60d) * 100;
  }

  if (treesPlantedGrowth > 0) {
    treesPlantedGrowth =
      ((treesPlanted - treesPlanted60d) / treesPlanted60d) * 100;
  }

  return (
    <>
      <ReactTooltip effect="solid" className="rounded-md" />
      <section className="py-8">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0 animate-enter transition-all">
            <div className="h-full px-8 py-10 bg-slate-50 shadow rounded-md">
              <div className="flex items-center mb-4">
                <h3 className="mr-auto pr-1 text-sm text-darkBlueGray-300 font-heading font-medium">
                  Recognition givers
                </h3>
                <div className="px-3 py-1 text-center bg-blue-200 bg-opacity-70 rounded-full">
                  <span className="text-sm font-bold text-blue-500 leading-6">
                    30 days
                  </span>
                </div>
              </div>

              <div className="flex items-center mb-5">
                <span className="inline-block mr-2 text-5xl font-heading font-medium">
                  {recognitionGivers}
                </span>
                <div
                  data-tip="Compared to previous period"
                  className="cursor-pointer"
                >
                  {recognitionGiversGrowth > 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-green-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-green-500 leading-4">
                        + {recognitionGiversGrowth.toFixed(0)} %
                      </span>
                    </div>
                  )}
                  {recognitionGiversGrowth === 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-yellow-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-yellow-500 leading-4">
                        - %
                      </span>
                    </div>
                  )}
                  {recognitionGiversGrowth < 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-red-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-red-500 leading-4">
                        {recognitionGiversGrowth.toFixed(0)} %
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0 animate-enter transition-all">
            <div className="h-full px-8 py-10 bg-slate-50 shadow rounded-md">
              <div className="flex items-center mb-4">
                <h3 className="mr-auto pr-1 text-sm text-darkBlueGray-300 font-heading font-medium">
                  Recognition sent
                </h3>
                <div className="px-3 py-1 text-center bg-blue-200 bg-opacity-70 rounded-full">
                  <span className="text-sm font-bold text-blue-500 leading-6">
                    30 days
                  </span>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <span className="inline-block mr-2 text-5xl font-heading font-medium">
                  {recognitionSent}
                </span>
                <div
                  data-tip="Compared to previous period"
                  className="cursor-pointer"
                >
                  {recognitionSentGrowth > 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-green-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-green-500 leading-4">
                        + {recognitionSentGrowth.toFixed(0)} %
                      </span>
                    </div>
                  )}
                  {recognitionSentGrowth === 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-yellow-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-yellow-500 leading-4">
                        - %
                      </span>
                    </div>
                  )}
                  {recognitionSentGrowth < 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-red-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-red-500 leading-4">
                        {recognitionSentGrowth.toFixed(0)} %
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0 animate-enter transition-all">
            <div className="h-full px-8 py-10 bg-slate-50 shadow rounded-md">
              <div className="flex items-center mb-4">
                <h3 className="mr-auto pr-1 text-sm text-darkBlueGray-300 font-heading font-medium">
                  Trees planted
                </h3>
                <div className="px-3 py-1 text-center bg-blue-200 bg-opacity-70 rounded-full">
                  <span className="text-sm font-bold text-blue-500 leading-6">
                    30 days
                  </span>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <span className="inline-block mr-2 text-5xl font-heading font-medium">
                  {treesPlanted}
                </span>
                <div
                  data-tip="Compared to previous period"
                  className="cursor-pointer"
                >
                  {treesPlantedGrowth > 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-green-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-green-500 leading-4">
                        + {treesPlantedGrowth.toFixed(0)} %
                      </span>
                    </div>
                  )}
                  {treesPlantedGrowth === 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-yellow-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-yellow-500 leading-4">
                        - %
                      </span>
                    </div>
                  )}
                  {treesPlantedGrowth < 0 && (
                    <div className="inline-flex items-center px-2 py-1 text-center bg-red-200 bg-opacity-70 rounded-full">
                      <span className="text-xxs ml-2 font-bold text-red-500 leading-4">
                        {treesPlantedGrowth.toFixed(0)} %
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StatsBoxes;
