import { useTable, usePagination, useSortBy } from "react-table";
import React, { useState, useEffect } from "react";
import { getTenantDataReporting } from "../../state/stateStore";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  DocumentDownloadIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { getProfilePictureURI } from "../Helpers/helpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Table = ({ columns, data, navigate }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 15,
        hiddenColumns: ["picture_uri", "_id"],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-md">
              <table
                {...getTableProps()}
                className="min-w-full divide-y table-auto divide-gray-300"
              >
                <thead className="bg-slate-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-medium text-slate-800"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}

                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const picture_uri = getProfilePictureURI(
                      row.values.picture_uri
                    );
                    const userId = row.values._id;
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                            >
                              <div className="text-gray-700 flex items-center">
                                {cell.column.id === "user_name" ? (
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={picture_uri}
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                <span
                                  className={
                                    cell.column.id === "user_name"
                                      ? "ml-4 font-semibold cursor-pointer text-primaryGreen hover:undeline"
                                      : ""
                                  }
                                  onClick={() => {
                                    if (cell.column.id === "user_name") {
                                      navigate("/profile/" + userId);
                                    }
                                  }}
                                >
                                  {cell.render("Cell")}
                                </span>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 flex items-center justify-between rounded-md mt-4">
              <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
                <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <select
                    className="relative py-2 pl-3 pr-10 text-left bg-white rounded-md border-none shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize} per page
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReportingTable = (props) => {
  let navigate = useNavigate();
  const [dateRange, setDateRange] = useState("30d");
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = React.useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const getMembersByDateRange = () => {
    getTenantDataReporting(startDate, endDate, false);
  };

  useEffect(() => {
    getMembersByDateRange();
  }, [endDate]);

  useEffect(() => {
    if (dateRange === "today") {
      setStartDate(moment().toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "yesterday") {
      setStartDate(moment().subtract(1, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "7d") {
      setStartDate(moment().subtract(7, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "30d") {
      setStartDate(moment().subtract(30, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "3m") {
      setStartDate(moment().subtract(90, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "6m") {
      setStartDate(moment().subtract(180, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "1y") {
      setStartDate(moment().subtract(365, "days").toDate());
      setEndDate(moment().toDate());
    }
  }, [dateRange]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "_id",
      },
      {
        Header: "Picture",
        accessor: "picture_uri",
      },
      {
        Header: "Name",
        accessor: "user_name",
      },
      {
        Header: "Recognitions given",
        accessor: "feedback_given",
      },
      {
        Header: "Recognitions received",
        accessor: "feedback_received",
      },
      {
        Header: "Seeds given",
        accessor: "seeds_given",
      },
      {
        Header: "Trees planted",
        accessor: "trees_planted",
      },
      {
        Header: "Carbon offset",
        accessor: "carbon_offset",
        Cell: (props) => {
          return <div>{props.value} kg</div>;
        },
      },
    ],
    []
  );

  let csvData = [];
  let tableData = [];
  //Map reporting data to CSV data and tableData
  props.reportingData &&
    props.reportingData.forEach((member) => {
      csvData.push({
        user_name: member.user_name,
        feedback_given: member.feedback_given,
        feedback_received: member.feedback_received,
        seeds_given: member.seeds_given,
        trees_planted: member.trees_planted,
        carbon_offset: member.trees_planted * 84,
      });
      tableData.push({
        _id: member._id,
        picture_uri: member.picture_uri,
        user_name: member.user_name,
        feedback_given: member.feedback_given,
        feedback_received: member.feedback_received,
        seeds_given: member.seeds_given,
        trees_planted: member.trees_planted,
        carbon_offset: member.trees_planted * 84,
      });
    });

  const data = tableData.filter((item) =>
    item.user_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <section className="py-8">
      <div className="container mx-auto">
        <div className="pt-14 pb-12 sm:pb-16 bg-slate-50 shadow rounded-md p-10">
          <div>
            <h3 className="mb-8 sm:mb-0 text-xl font-heading text-slate-600 font-medium leading-8">
              People Analytics
            </h3>
            <p className="text-slate-500 mt-2 font-medium text-sm">
              <InformationCircleIcon
                className="h-4 w-4 float-left mr-1 mt-0.5"
                aria-hidden="true"
              />
              Click name to view more information
            </p>
            <div className="flex flex-wrap mb-4 -mx-4">
              <div className="w-full lg:w-2/6 md:w-full min-w-300 px-4">
                <h5 className="text-sm font-medium mt-6 mb-2 text-slate-500 leading-relaxed">
                  Select date range
                </h5>
                <div className="block flex rounded-md mb-2" role="group">
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "today"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor rounded-l-xl shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("today")}
                  >
                    Today
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "7d"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("7d")}
                  >
                    7D
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "30d"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("30d")}
                  >
                    30D
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "6m"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("6m")}
                  >
                    6M
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "1y"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor  rounded-r-xl shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("1y")}
                  >
                    1Y
                  </button>
                </div>
              </div>
              <div className="w-full lg:w-2/6 md:w-full">
                <div className="flex flex-wrap">
                  <div className="w-1/2 px-4">
                    <h5 className="text-sm font-medium mt-6 mb-2 text-slate-500 leading-relaxed">
                      From
                    </h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      dateFormat="dd.MM.yyyy"
                      endDate={endDate}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="flex items-center justify-between px-2 py-2">
                          <span className="text-lg text-gray-700">
                            {format(date, "MMMM yyyy")}
                          </span>

                          <div className="space-x-2">
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                              type="button"
                              className={`
                            ${
                              prevMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                            >
                              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                            </button>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                              type="button"
                              className={`
                            ${
                              nextMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                            >
                              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="w-1/2 px-4">
                    <h5 className="text-sm font-medium mt-6 mb-2 text-slate-500 leading-relaxed">
                      To
                    </h5>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      dateFormat="dd.MM.yyyy"
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={Date.now()}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="flex items-center justify-between cursor-pointer px-2 py-2">
                          <span className="text-lg text-gray-700">
                            {format(date, "MMMM yyyy")}
                          </span>

                          <div className="space-x-2">
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                              type="button"
                              className={`
                            ${
                              prevMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                            >
                              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                            </button>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                              type="button"
                              className={`
                            ${
                              nextMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                            >
                              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/6 sm:w-full px-4 md:w-full">
                <h5 className="text-sm font-medium sm:ml-4 mt-6 mb-2 text-slate-500 leading-relaxed">
                  Search
                </h5>
                <input
                  id="search"
                  type="text"
                  placeholder="Search by name.."
                  className="w-full text-base md:text-sm sm:ml-4 bg-white border-none rounded-md shadow form-input focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-green-500"
                  onChange={handleSearch}
                />
              </div>
              <div className="w-full lg:w-1/6 md:w-full">
                <CSVLink
                  data={csvData}
                  filename={"evergreen-reporting-data.csv"}
                  className="mb-2 inline-flex mt-14 mr-2 float-right items-center px-3 py-2 text-xs hover:bg-green-100 rounded-md leading-4 font-medium text-primaryGreen"
                  target="_blank"
                >
                  <DocumentDownloadIcon
                    className="mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  <span className="-mx-0.5 mt-0.5">Download as CSV</span>
                </CSVLink>
              </div>
            </div>
            <Table columns={columns} data={data} navigate={navigate} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReportingTable;
