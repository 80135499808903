import { useState, useRef } from "react";
import { Switch } from "@headlessui/react";
import {
  ChartSquareBarIcon,
  DocumentTextIcon,
  PencilAltIcon,
  SaveIcon,
} from "@heroicons/react/outline";
import * as api from "../../services/api";
import { createToast } from "../Helpers/toaster";
import { getCurrentTenant } from "../../state/stateStore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ImpactDashboard = ({ currentTenant }) => {
  const [statementEdit, setStatementEdit] = useState(false);
  const ref = useRef(null);

  const changeImpactBoolean = async (boolean) => {
    await api.toggleImpactDash(boolean);
    await getCurrentTenant();
    if (boolean) {
      createToast("TOGGLE_IMPACT_ON");
    } else {
      createToast("TOGGLE_IMPACT_OFF");
    }
  };

  const updateImpactStatement = async () => {
    const statement = ref.current.value;
    if (statement) {
      await api.updateImpactStatement(statement);
      await getCurrentTenant();
      createToast("IMPACT_STATEMENT_UPDATED");
      setStatementEdit(false);
    }
  };

  return (
    <div className="mt-4">
      <p className="mt-1 text-sm text-gray-600">
        Impact page is a public display of your organization's Evergreen
        statistics and a public impact statement.
      </p>
      <ul role="list" className="mt-2 divide-y divide-gray-200">
        <Switch.Group
          as="li"
          className="py-4 flex items-center justify-between"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <ChartSquareBarIcon
                className="h-6 w-6 mr-3 mt-2 text-gray-600"
                aria-hidden="true"
              />
            </div>
            <div className="flex flex-col">
              <Switch.Label
                as="p"
                className="text-sm font-medium text-gray-900"
                passive
              >
                Enable Impact page
              </Switch.Label>
              <Switch.Description className="text-sm text-gray-600">
                Change your public Impact page visibility
              </Switch.Description>
            </div>
          </div>
          <Switch
            checked={currentTenant.settings.impact_dashboard_enabled}
            onChange={changeImpactBoolean}
            className={classNames(
              currentTenant.settings.impact_dashboard_enabled
                ? "bg-primaryGreen"
                : "bg-gray-200",
              "ml-20 relative inline-flex mt-4 flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                currentTenant.settings.impact_dashboard_enabled
                  ? "translate-x-5"
                  : "translate-x-0",
                "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
        </Switch.Group>
        <li className="py-4 flex items-center justify-between">
          <div className="flex">
            <div className="flex-shrink-0">
              <DocumentTextIcon
                className="h-6 w-6 mr-3 mt-2 text-gray-600"
                aria-hidden="true"
              />
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-medium text-gray-900">
                Impact statement
              </p>
              <p className="text-sm text-gray-600">
                Describe your organization's contribution to making an impact.
              </p>
            </div>
          </div>
          <div className="flex basis-1/2 mx-4">
            {!currentTenant.settings.impact_statement && !statementEdit && (
              <p className="text-sm text-gray-600 mx-4">
                Add an impact statement for your company's public impact page by
                editing this text.
              </p>
            )}
            {currentTenant.settings.impact_statement && !statementEdit && (
              <p className="text-sm  mx-4">
                {currentTenant.settings.impact_statement}
              </p>
            )}

            {statementEdit && (
              <div className="w-full">
                <label
                  htmlFor="impact-statement"
                  className="block text-sm font-medium text-gray-700"
                >
                  Impact Statement
                </label>
                <textarea
                  id="impact-statement"
                  name="impact-statement"
                  ref={ref}
                  rows={3}
                  className="shadow-sm w-full focus:ring-primaryGreen focus:border-primaryGreen sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={currentTenant.settings.impact_statement}
                />
                <p className="mt-1 text-sm text-gray-500">
                  Write a short impact statement.
                </p>
              </div>
            )}
          </div>
          <div className="flex">
            {!statementEdit && (
              <button
                type="button"
                className="inline-flex ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-orange-700 bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                onClick={() => setStatementEdit(true)}
              >
                <PencilAltIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Edit
              </button>
            )}
            {statementEdit && (
              <button
                type="button"
                className="inline-flex ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => updateImpactStatement()}
              >
                <SaveIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Update
              </button>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ImpactDashboard;
