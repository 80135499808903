import { getProfilePictureURI } from "../../Helpers/helpers";

const TopLists = ({ senders, receivers, viewMode, showMode }) => {
  let sortedReceivers = receivers
    .slice()
    .sort(
      (a, b) =>
        b.feedback_received_note_history.length -
        a.feedback_received_note_history.length
    );

  let sortedSenders = senders
    .slice()
    .sort(
      (a, b) =>
        b.feedback_given_note_history.length -
        a.feedback_given_note_history.length
    );
  if (viewMode === "small") {
    sortedSenders = sortedSenders.slice(0, 5);
    sortedReceivers = sortedReceivers.slice(0, 5);
  } else if (viewMode === "full") {
    sortedSenders = sortedSenders.slice(0, 50);
    sortedReceivers = sortedReceivers.slice(0, 50);
  }
  return (
    <section className="py-6">
      <div className="flex justify-center">
        <div className="w-full lg:w-1/2 mr-6 rounded-md bg-slate-50 p-6 py-4 shadow">
          <div className="w-full mb-4">
            <h3 className="mr-auto text-lg font-medium text-darkBlueGray-300 font-normal">
              🏆 Tag Champions
            </h3>
            <p className="text-sm text-gray-500">
              People who have received most recognition for the tag.
            </p>
          </div>
          {sortedReceivers.length > 0 ? (
            <ul className="divide-y divide-gray-200">
              {sortedReceivers.map((receiver, i) => (
                <li key={receiver._id} className="flex py-4">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={getProfilePictureURI(receiver.picture_uri)}
                    alt={receiver.name}
                  />
                  {i === 0 && (
                    <div className="absolute text-2xl mt-6  ml-8 ">🥇</div>
                  )}
                  {i === 1 && (
                    <div className="absolute text-2xl mt-6  ml-8 ">🥈</div>
                  )}
                  {i === 2 && (
                    <div className="absolute text-2xl mt-6  ml-8 ">🥉</div>
                  )}
                  <div className="ml-3 justify-items-center items-center flex">
                    <p className="text-lg font-medium text-gray-900">
                      {receiver.name}
                    </p>
                  </div>
                  <div className="align-self-end ml-auto">
                    <span className="inline-block text-md px-4 py-2 font-heading rounded-lg text-gray-800 bg-green-200">
                      {receiver.feedback_received_note_history.length}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-center border-2 border-dotted border-gray-400 rounded-md  py-8 text-gray-500">
              No recognition for this tag during the selected timeframe.
            </p>
          )}
        </div>
        <div className="w-full lg:w-1/2 ml-6 rounded-md bg-slate-50 p-6 py-4 shadow">
          <div className="w-full mb-4">
            <h3 className="mr-auto text-lg font-medium text-darkBlueGray-300 font-normal">
              🤝 Tag Advocates
            </h3>
            <p className="text-sm text-gray-500">
              People who sent most recognition using the tag.
            </p>
          </div>
          {sortedSenders.length > 0 ? (
            <ul className="divide-y divide-gray-200">
              {sortedSenders.map((sender, i) => (
                <li key={sender._id} className="flex py-4">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={getProfilePictureURI(sender.picture_uri)}
                    alt={sender.name}
                  />
                  {i === 0 && (
                    <div className="absolute text-2xl mt-6  ml-8 ">🥇</div>
                  )}
                  {i === 1 && (
                    <div className="absolute text-2xl mt-6  ml-8 ">🥈</div>
                  )}
                  {i === 2 && (
                    <div className="absolute text-2xl mt-6  ml-8 ">🥉</div>
                  )}
                  <div className="ml-3 justify-items-center items-center flex">
                    <p className="text-lg font-medium text-gray-900">
                      {sender.name}
                    </p>
                  </div>
                  <div className="align-self-end ml-auto">
                    <span className="inline-block text-md px-4 py-2 font-heading rounded-lg text-gray-800 bg-green-200">
                      {sender.feedback_given_note_history.length}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-center border-2 border-dotted border-gray-400 rounded-md py-8 text-gray-500">
              No recognition for this tag during the selected timeframe.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopLists;
