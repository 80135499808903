import { useEffect } from "react";
import { state, useSnapshot, getCurrentTenant } from "../../state/stateStore";
import { TagIcon, CollectionIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import TagsStatsBoxes from "./TagsStatsBoxes";
import { getProfilePictureURI } from "../Helpers/helpers";

const TagCard = ({ tag, ranking, navigate }) => {
  ranking = ranking + 1;
  const emoji = tag.emoji;
  const name = tag.name;

  let rankingText, rankingColor;

  switch (ranking) {
    case 1:
      rankingText = "1st 🥇";
      rankingColor = "text-amber-500";
      break;
    case 2:
      rankingText = "2nd 🥈";
      rankingColor = "text-gray-500";
      break;
    case 3:
      rankingText = "3rd 🥉";
      rankingColor = "text-yellow-500";
      break;
    default:
      rankingText = `${ranking}th`;
      rankingColor = "text-gray-800";
  }

  return (
    <div className="w-full lg:w-1/4 px-2 lg:mb-0 animate-enter transition-all">
      <div className="h-full px-6 py-6 bg-white shadow flex flex-col rounded-md">
        <div className="flex font-semibold items-start">
          <div className="text-2xl bg-slate-100 border border-gray-200 rounded-md h-10 w-10 px-2 py-0.5">
            {emoji}
          </div>
          <div className="text-lg -mt-1 ml-3">{name}</div>
        </div>
        <div className="grid grid-cols-8 mt-6 gap-y-3 gap-x-2">
          <div className="text-sm text-gray-500 col-span-6">
            📣 Recognitions
          </div>
          <div className="text-sm font-semibold col-span-2">
            {tag.recognitions.length}
          </div>
          <div className="text-sm text-gray-500 col-span-6">🏆 Popularity</div>
          <div className={`${rankingColor} text-sm font-semibold col-span-2`}>
            {rankingText}
          </div>
        </div>
        <div className="isolate flex -space-x-1 overflow-hidden my-4">
          {tag.top5.map((person) => (
            <img
              key={person._id}
              className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white"
              src={getProfilePictureURI(person.picture_uri)}
              alt={person.name}
            />
          ))}
          {tag.top5.length === 0 && (
            <div className="h-6 w-6 rounded-full ring-2 ring-white" />
          )}
        </div>
        <button
          className="w-full mt-4 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-green-200 focus:outline-none"
          onClick={() => navigate(`/tag/${tag._id}`)}
        >
          View statistics
        </button>
      </div>
    </div>
  );
};

const Tags = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentTenant();
  }, []);

  // State
  const snap = useSnapshot(state);
  const currentTenant = snap.currentTenant;
  const currentUser = snap.currentUser;
  const isLoading = snap.isLoading;

  if (!isLoading && currentTenant) {
    const tagCollections = currentTenant.tagCollections;

    let rankingArray = [];
    let collectionRankingArray = [];
    let totalAmount = 0;

    if (tagCollections) {
      tagCollections.forEach((tagCollection) => {
        let collectionRecognitions = 0;
        tagCollection.tags.forEach((tag) => {
          rankingArray.push({
            id: tag._id,
            name: tag.emoji + " " + tag.name,
            count: tag.recognitions.length,
          });
          totalAmount += tag.recognitions.length;
          collectionRecognitions += tag.recognitions.length;
        });
        collectionRankingArray.push({
          name: tagCollection.collection_name,
          count: collectionRecognitions,
        });
      });

      if (rankingArray.length > 0) {
        rankingArray.sort((a, b) => b.count - a.count);
      }
      if (collectionRankingArray.length > 0) {
        collectionRankingArray.sort((a, b) => b.count - a.count);
      }
    }
    const tagsData = {
      totalRecognitions: totalAmount,
      rankingArray: rankingArray,
      collectionRankingArray: collectionRankingArray,
    };

    return (
      <>
        <section className="py-6 animate-fade">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container my-8">
              <div className="flex">
                <h2 className="text-2xl flex float-left  font-semibold text-slate-600 leading-relaxed">
                  <TagIcon
                    className="float-left h-8 w-8 mt-1 mr-2"
                    aria-hidden="true"
                  />{" "}
                  Tags
                </h2>
                {currentUser.roles.includes("admin") && (
                  <button
                    className="float-left ml-6 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent leading-4 font-medium text-sm rounded-md bg-primaryGreen text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => navigate("/tags/manage")}
                  >
                    Manage tags
                  </button>
                )}
              </div>
              <p className="text-slate-600 mt-2">
                Create and manage tags for {currentTenant.display_name}
              </p>
            </div>
            <TagsStatsBoxes tagsData={tagsData} />
            <section>
              <div className="flex flex-wrap -mx-2">
                {tagCollections && tagCollections.length === 0 && (
                  <div className="h-full px-6 py-24 bg-slate-50 shadow text-center rounded-md w-full">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No tag collections or tags
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a new tag collection from manage
                      tags page.
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        onClick={() => navigate("/tags/manage")}
                      >
                        Manage tags
                      </button>
                    </div>
                  </div>
                )}
                {tagCollections &&
                  tagCollections.map((tagCollection, i) => {
                    return (
                      <div
                        className="w-full mb-4 px-2 animate-enter transition-all"
                        key={i}
                      >
                        <div className="h-full px-6 pt-4 pb-6 bg-slate-50 shadow rounded-md">
                          <div className="flex mb-4">
                            <h3 className="pr-1 text-md text-slate-700 font-heading font-medium">
                              <CollectionIcon
                                className="float-left h-4 w-4 mt-1 mr-1"
                                aria-hidden="true"
                              />
                              {tagCollection.collection_name}
                            </h3>
                          </div>
                          <div className="flex flex-wrap -mx-2">
                            {tagCollection.tags.map((tag) => {
                              const ranking = tagsData.rankingArray.findIndex(
                                (item) =>
                                  item.name === tag.emoji + " " + tag.name
                              );
                              return (
                                <TagCard
                                  tag={tag}
                                  navigate={navigate}
                                  ranking={ranking}
                                />
                              );
                            })}
                            {tagCollection.tags.length === 0 && (
                              <button
                                type="button"
                                className="relative block w-full border-2 border-gray-300 border-dashed rounded-md p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => navigate("/tags/manage")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                  />
                                </svg>
                                <span className="mt-2 block text-sm font-normal text-gray-900">
                                  No tags added. Click here to manage tags for
                                  tag collection.
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>
          </div>
        </section>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Tags;
