import { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  state,
  useSnapshot,
  getViewableTag,
  getViewableTagPreviousPeriod,
  getTagRecognitionsByMonth,
  clearViewableTag,
} from "../../../state/stateStore";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import moment from "moment";
import TagStatsBoxes from "./TagStatsBoxes";
import TagChart from "./TagChart";
import TopLists from "./TopLists";
import TopListsModal from "./TopListsModal";
import { LoadingIndicator } from "../../Helpers/helpers";
const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Tag = () => {
  const snap = useSnapshot(state);
  const currentTenant = snap.currentTenant;
  const currentUser = snap.currentUser;
  const viewableTag = snap.viewableTag;
  const viewableTagPreviousPeriod = snap.viewableTagPreviousPeriod;
  const viewableTagRecognitionsMonthly = snap.viewableTagRecognitionsMonthly;

  const params = useParams();
  const tagId = params.tagId;

  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment(currentTenant.create_date).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  const [showTopListsModal, setShowTopListsModal] = useState(false);
  const [showMode, setShowMode] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getViewableTag(tagId, startDate, endDate);

      const periodLength = moment(endDate).diff(startDate, "days");
      const previousPeriodEndDate = startDate;
      const previousPeriodStartDate = moment(startDate)
        .subtract(periodLength, "days")
        .toDate();

      await getViewableTagPreviousPeriod(
        tagId,
        previousPeriodStartDate,
        previousPeriodEndDate
      );

      await getTagRecognitionsByMonth(
        moment().month() + 1,
        moment().year(),
        tagId
      );
      setIsLoading(false);
    };
    fetchData().catch(console.error);
  }, [startDate, endDate, tagId]);

  useEffect(() => {
    if (dateRange === "today") {
      setStartDate(moment().toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "yesterday") {
      setStartDate(moment().subtract(1, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "7d") {
      setStartDate(moment().subtract(7, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "30d") {
      setStartDate(moment().subtract(30, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "3m") {
      setStartDate(moment().subtract(90, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "6m") {
      setStartDate(moment().subtract(180, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "1y") {
      setStartDate(moment().subtract(365, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "all") {
      setStartDate(moment(currentTenant.create_date).toDate());
      setEndDate(moment().toDate());
    }
  }, [dateRange]);

  if (
    currentUser &&
    currentTenant &&
    viewableTag &&
    viewableTagPreviousPeriod &&
    viewableTagRecognitionsMonthly
  ) {
    const statsData = {
      tagUsedAmount: {
        current: viewableTag.tagUsedAmount,
        previous: viewableTagPreviousPeriod.tagUsedAmount,
      },
      tagUsagePercentage: {
        current: viewableTag.tagUsagePercentage,
        previous: viewableTagPreviousPeriod.tagUsagePercentage,
      },
      tagRanking: {
        current: viewableTag.tagRanking,
        previous: viewableTagPreviousPeriod.tagRanking,
      },
    };
    return (
      <>
        <div className="py-6 pb-12 animate-fade">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex items-center space-x-5 mt-8 py-4">
              <div>
                <h1 className="text-3xl font-bold text-gray-900">
                  {viewableTag.emoji} {viewableTag.name}
                  <button
                    onClick={() => {
                      navigate(-1);
                      clearViewableTag();
                    }}
                    className=" ml-6 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-slate-700 bg-slate-100 hover:bg-slate-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                  >
                    <ChevronLeftIcon
                      className="float-left h-4 w-4 mr-1 -ml-1"
                      aria-hidden="true"
                    />
                    Back
                  </button>
                </h1>
                <p className="text-slate-600 mt-4">
                  Tag related usage data and statistics
                </p>
              </div>
            </div>
            <div className="flex flex-wrap z-20 sticky top-5 mb-2 rounded-md bg-slate-50 p-4 shadow-md">
              <div className="w-full lg:w-3/6 md:w-full min-w-300 px-4">
                <h5 className="text-sm font-medium mb-2 text-slate-500 leading-relaxed">
                  Select date range
                </h5>
                <div className="flex rounded-md mb-2" role="group">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            dateRange === "custom"
                              ? "bg-green-300 text-green-800"
                              : "bg-white",
                            "py-2 px-4 text-sm font-medium text-mutedTextColor rounded-l-xl shadow hover:bg-green-300 hover:text-green-800"
                          )}
                        >
                          <CalendarIcon
                            className="float-left h-4 w-4 mr-1 mt-0.5"
                            aria-hidden="true"
                          />
                          Custom
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-md">
                            <div className="overflow-hidden rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                              <div className="bg-slate-50 p-7">
                                <h5 className="text-md font-medium mb-4 leading-relaxed">
                                  Select a custom date range
                                </h5>
                                <div className="w-full md:w-full">
                                  <div className="flex">
                                    <div className="w-1/2 pr-4">
                                      <h5 className="text-sm font-medium mb-2 text-slate-500 leading-relaxed">
                                        Start date
                                      </h5>
                                      <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                          setStartDate(date);
                                          setDateRange("custom");
                                        }}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsStart
                                        dateFormat="dd.MM.yyyy"
                                        renderCustomHeader={({
                                          date,
                                          decreaseMonth,
                                          increaseMonth,
                                          prevMonthButtonDisabled,
                                          nextMonthButtonDisabled,
                                        }) => (
                                          <div className="flex items-center justify-between px-2 py-2">
                                            <span className="text-lg text-gray-700">
                                              {format(date, "MMMM yyyy")}
                                            </span>

                                            <div className="space-x-2">
                                              <button
                                                onClick={decreaseMonth}
                                                disabled={
                                                  prevMonthButtonDisabled
                                                }
                                                type="button"
                                                className={`
                            ${
                              prevMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                                              >
                                                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                                              </button>

                                              <button
                                                onClick={increaseMonth}
                                                disabled={
                                                  nextMonthButtonDisabled
                                                }
                                                type="button"
                                                className={`
                            ${
                              nextMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                                              >
                                                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      />
                                    </div>
                                    <div className="w-1/2 pl-4">
                                      <h5 className="text-sm font-medium  mb-2 text-slate-500 leading-relaxed">
                                        End date
                                      </h5>
                                      <DatePicker
                                        selected={endDate}
                                        onChange={(date) => {
                                          setEndDate(date);
                                          setDateRange("custom");
                                        }}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsEnd
                                        dateFormat="dd.MM.yyyy"
                                        minDate={startDate}
                                        maxDate={Date.now()}
                                        renderCustomHeader={({
                                          date,
                                          decreaseMonth,
                                          increaseMonth,
                                          prevMonthButtonDisabled,
                                          nextMonthButtonDisabled,
                                        }) => (
                                          <div className="flex items-center justify-between cursor-pointer px-2 py-2">
                                            <span className="text-lg text-gray-700">
                                              {format(date, "MMMM yyyy")}
                                            </span>

                                            <div className="space-x-2">
                                              <button
                                                onClick={decreaseMonth}
                                                disabled={
                                                  prevMonthButtonDisabled
                                                }
                                                type="button"
                                                className={`
                            ${
                              prevMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                                              >
                                                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                                              </button>

                                              <button
                                                onClick={increaseMonth}
                                                disabled={
                                                  nextMonthButtonDisabled
                                                }
                                                type="button"
                                                className={`
                            ${
                              nextMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                                              >
                                                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "today"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("today")}
                  >
                    Today
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "7d"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("7d")}
                  >
                    7D
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "30d"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("30d")}
                  >
                    30D
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "6m"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("6m")}
                  >
                    6M
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "1y"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("1y")}
                  >
                    1Y
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      dateRange === "all"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor rounded-r-xl shadow hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setDateRange("all")}
                  >
                    All
                  </button>
                </div>
              </div>

              <div className="w-full lg:w-2/6 md:w-full pt-6">
                {isLoading && (
                  <div>
                    <LoadingIndicator />
                  </div>
                )}
              </div>
            </div>
            <TagStatsBoxes
              statsData={statsData}
              dateRange={dateRange}
              isLoading={isLoading}
            />
            <TagChart
              tagId={tagId}
              tagName={viewableTag.name}
              setIsLoading={setIsLoading}
              creationDate={currentTenant.create_date}
              tagRecognitionsMonthly={viewableTagRecognitionsMonthly}
            />
            <TopListsModal
              isOpen={showTopListsModal}
              closeModal={() => setShowTopListsModal(false)}
              receivers={viewableTag.receivers}
              senders={viewableTag.senders}
              showMode={showMode}
            />
            <TopLists
              receivers={viewableTag.receivers}
              senders={viewableTag.senders}
              viewMode="small"
              openModal={(showMode) => {
                setShowMode(showMode);
                setShowTopListsModal(true);
              }}
            />
            <div className="flex justify-center">
              <button
                type="button"
                onClick={() => setShowTopListsModal(true)}
                className="flex items-center px-3 py-2 border border-transparent float-right text-sm leading-4 font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none"
              >
                View top 50 lists
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Tag;
