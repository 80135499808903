import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";

export default function TrialModal({ isOpen, closeModal }) {
  const startSlackTrial = () => {
    const installUrl = "https://evergreen-bot.herokuapp.com/install";
    window.open(installUrl, "_self");
  };

  const startTeamsTrial = () => {
    const installUrl =
      "https://appsource.microsoft.com/en-us/product/office/WA200004548";
    window.open(installUrl, "_self");
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="z-10 max-w-7xl overflow-y-auto"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-sm bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center overflow-y-auto p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-7xl transform overflow-visible rounded-xl flex-col h-full bg-white px-36 pt-24 pb-10 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-3xl font-headline leading-6 text-gray-500"
                >
                  <div
                    className="absolute top-0 right-0 p-4 cursor-pointer"
                    onClick={() => closeModal()}
                  >
                    <XCircleIcon className="h-7 w-7" />
                  </div>
                </Dialog.Title>
                <div className="max-w-2xl flex flex-col grow justify-items-center justify-center">
                  <div className="text-center">
                    <p className="text-5xl font-headline font-semibold text-gray-800">
                      Start feeling good about work
                    </p>
                    <p className="text-lg mt-10">
                      For only $3.99 per active user a month. In the 14 day free
                      trial we don’t plant real trees, but you can skip the
                      trial if you like.
                    </p>
                  </div>
                  <div className="flex-col px-24 items-center space-y-6 mt-10">
                    <div
                      className="bg-black text-white px-6 py-4 rounded-full justify-center hover:scale-110 transition-all duration-500 flex align-middle items-center font-semibold text-2xl cursor-pointer"
                      onClick={startSlackTrial}
                    >
                      <img
                        className="h-8 w-8 mr-3"
                        src={process.env.PUBLIC_URL + "/slack-logo.svg"}
                        alt="Evergreen"
                      />
                      <p>Start with Slack</p>
                    </div>
                    <div
                      className="bg-black text-white px-6 py-4 rounded-full justify-center hover:scale-110 transition-all duration-500 flex align-middle items-center font-semibold text-2xl cursor-pointer"
                      onClick={startTeamsTrial}
                    >
                      <img
                        className="h-8 w-8 mr-3"
                        src={process.env.PUBLIC_URL + "/teams-logo.svg"}
                        alt="Evergreen"
                      />
                      Start with Teams
                    </div>
                  </div>
                  <p className="text-gray-400 mt-8 text-sm text-center">
                    By installing Evergreen, you agree to our{" "}
                    <a
                      href="https://www.evergreen.so/terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:underline cursor-pointer text-primaryGreen focus:outline-none"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.evergreen.so/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:underline cursor-pointer text-primaryGreen focus:outline-none"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
                <div className="flex flex-shrink-0 justify-center text-gray-600 text-center text-md mt-24">
                  Want to learn more?
                  <a
                    href="https://www.evergreen.so/schedule-a-demo"
                    className="ml-1 hover:underline text-primaryGreen hover:text-primaryGreen cursor-pointer"
                  >
                    Schedule a demo with us
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
